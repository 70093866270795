import React from "react";
import { AdvanceTablesWidget12 } from "../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget12";

export function CategoriesViewProfile(props) {
    return (
        <div className="row">
            <div className="col-lg-12">
                <AdvanceTablesWidget12 props={props}></AdvanceTablesWidget12>
                
            </div>
        </div>
    );
}
