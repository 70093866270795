/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
// import UserChallenge from "./UserChallenge";
import {
  ActionButtonForUser,
  DetailsRow,
  PageInnerContainer,
  UserInfoBox,
  UserProfileInfo,
} from "../../../GobalStyled";
import { getErrorMessage } from "../../../utils/functions";
import {
  activeDeactivePostProfile,
  postProfileDetails,
  userDelete,
} from "../Auth/_redux/authCrud";
import DeleteBlock from "../Modals/DeleteBlock";
import UserInfo from "./UserInfo";
import UserMedia from "./UserMedia";
export default function ViewProfile(props) {
  const [activeTab, setActiveTab] = useState("info");
  const [likeData, setLikeData] = useState("");
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [parameter, setParameter] = useState({});
  const [modalLoder, setModalLoder] = useState(false);
  const history = useHistory();
  const baseUrl = "https://d17am3tdlggecz.cloudfront.net/";
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getDetails = async () => {
    enableLoading();
    // console.log(props.location)
    const res = await postProfileDetails(props.location.state.id);
    if (res.status === 200) {
      // console.log(res.data)
      setUserData(res.data);
      setLikeData(res.extraData);
      setDetails(res.userDetails);

      disableLoading();
    } else {
      const message = getErrorMessage(res, "Failed to forgotten password");
      toast.error(message, { theme: "colored" });
      disableLoading();
    }
  };

  const ActiveDeactive = (data) => {
    setOpenModal(true);
    if (data?.profile_status === true) {
      let para = {
        name: "Deactivate",
        body: "this user",
        id: data?._id,
        status: false,
      };
      setParameter(para);
    }
    if (!data?.profile_status === true) {
      let para = {
        name: "Activate",
        body: "this user",
        id: data._id,
        status: true,
      };
      setParameter(para);
    }
  };

  const deleteUser = (id) => {
    setOpenModal(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: id,
    };
    setParameter(para);
  };

  // const getLocation = () => {
  //   let href="https://nominatim.openstreetmap.org/reverse?lat=30.7130097&lon=76.7092797&format=json"
  // }

  const deleteAction = async () => {
    setModalLoder(true);
    if (parameter.name === "Delete") {
      let res = await userDelete(parameter.id);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        history.push(props.location.state.path, { id: userData?.c_id });

        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
    if (parameter.name === "Activate" || parameter.name === "Deactivate") {
      let req = {
        profileId: parameter.id,
        profileStatus: parameter.status,
      };
      let res = await activeDeactivePostProfile(req);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        getDetails();
        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <DeleteBlock
          open={openModal}
          close={() => setOpenModal(false)}
          confirmSumbit={deleteAction}
          parameter={parameter}
          loading={modalLoder}
        />
      )}
      {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}

      {userData ? (
        <PageInnerContainer>
          <UserInfoBox>
            <UserProfileInfo>
              {console.log(details.profile_picture)}
              {details?.profile_picture ? (
                <img src={baseUrl + details?.profile_picture} alt="Profile" />
              ) : (
                <img src="../media/svg/avatars/001-boy.svg" alt="Profile" />
              )}
              <div>
                <h5 style={{ maxWidth: "250px", minWidth: "150px" }}>
                  {userData?.first_name + " " + userData?.last_name}
                </h5>
                <InfoContainer>
                  <div>
                    <span className="text-secondary">Username</span>
                  </div>
                  <div>
                    <span>{userData?.user_name}</span>
                  </div>
                  <div>
                    <i
                      class="fa fa-thumbs-up "
                      style={{ fontSize: "15px" }}
                      aria-hidden="true"
                    ></i>
                    <span className="mt-2 ml-3 h5 text-secondary">
                      {likeData !== "" ? likeData?.likes?.length : 0}
                    </span>

                    <i
                      class="fa fa-thumbs-down ml-3 mt-0"
                      style={{ fontSize: "15px" }}
                      aria-hidden="true"
                    ></i>
                    <span className=" mt-2 ml-3 h5 text-secondary">
                      {likeData !== "" ? likeData?.dislikes?.length : 0}
                    </span>
                  </div>
                </InfoContainer>
              </div>
              <div className="p-5">
                <a
                  href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${details?.email}`}
                  target="_blank"
                >
                  <span className="contact p-2">
                    <button className="btn btn-light-primary  btn-sm font-weight-bolder font-size-sm mx-1">
                      Contact User
                    </button>
                  </span>
                </a>
              </div>
            </UserProfileInfo>

            <ActionButtonForUser>
              <span className="Block" onClick={() => ActiveDeactive(userData)}>
                {userData?.profile_status !== true ? "Activate " : "Deactivate"}
              </span>

              <span className="Delete" onClick={() => deleteUser(userData._id)}>
                {userData?.deleted === true ? "delete" : "Delete"}
              </span>
            </ActionButtonForUser>
          </UserInfoBox>
          <DetailsRow tab={activeTab === "info" ? 1 : 2}>
            <div>
              <span
                className={activeTab === "info" && "active"}
                onClick={() => setActiveTab("info")}
              >
                Info
              </span>
            </div>
            <div>
              <span
                className={activeTab === "video" && "active"}
                onClick={() => setActiveTab("video")}
              >
                Post Profile
              </span>
            </div>
          </DetailsRow>
          {activeTab === "info" && <UserInfo data={userData} />}
          {activeTab === "video" && <UserMedia data={userData} />}
        </PageInnerContainer>
      ) : (
        <span className="ml-3 spinner spinner-white"></span>
      )}
    </>
  );
}

const InfoContainer = styled.div`
display: "grid",
grid-template-columns: "repeat(2,1fr)",
max-width: "200px",
`;
