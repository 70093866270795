/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
// import { useHistory } from "react-router-dom";
import {
  contactusListing,
  updateContactStatus,
} from "../../../../app/modules/Auth/_redux/authCrud";
import { ViewMessage } from "../../../../app/modules/ContactUs/Components/ViewMessage";
import { NoData, PageChanger, PagedDiv } from "../../../../GobalStyled";
import { toAbsoluteUrl } from "../../../_helpers";
export function AdvanceTablesWidget13({ className }) {
  // const history = useHistory();
  // const [clicked, setClicked] = useState(false)
  const [contactUsData, setcontactUsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const [page, setPage] = useState(1);
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleUpdate = async (val) => {
    if (val.status === false) {
      let req = {
        contactId: val._id,
        contactStatus: true,
      };
      enableLoading();
      await updateContactStatus(req)
        .then(() => {
          toast.success("Approved");
          getcontactUsDataListing();
          disableLoading();
        })
        .catch(() => {
          disableLoading();
        });
    }
  };
  const getcontactUsDataListing = () => {
    enableLoading();
    contactusListing(page)
      .then(({ data }) => {
        setcontactUsData(data);
        console.log(data);
        disableLoading();
        // dispatch(loginAdmin(data))
      })
      .catch(() => {
        disableLoading();
      });
  };

  useEffect(() => {
    getcontactUsDataListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }
  if (contactUsData.length > 0) {
    return (
      <>
        {/* begin::Advance Table Widget 9 */}
        <div className={`card card-custom ${className}`}>
          {/* begin::Header */}
          {/* end::Header */}
          {/* begin::Body */}
          <div className="card-body pt-3 pb-3" style={{ minHeight: "60vh" }}>
            <div className="tab-content">
              {/* begin::Table */}
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead className="pb-4">
                    <tr className="text-left">
                      <th style={{ minWidth: "35%" }} className="pl-7">
                        <span>Email</span>
                      </th>
                      <th style={{ minWidth: "35%", textAlign: "center" }}>
                        Subject
                      </th>
                      <th
                        style={{
                          minWidth: "20%",
                          maxWidth: "400px",
                          textAlign: "center",
                        }}
                      >
                        Message
                      </th>

                      <th style={{ minWidth: "35%", textAlign: "center" }}>
                        Status
                      </th>
                      <th style={{ minWidth: "35%", textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactUsData?.map((val) => (
                      <tr>
                        <td className="pl-0 py-0">
                          <div className="d-flex align-items-center">
                            <span>
                              <a
                                // onClick={() =>
                                //   history.push("/category-profiles", {
                                //     id: val?._id,
                                //     category: val?.category_name,
                                //   })
                                // }
                                className="text-dark-75 font-weight-bolder text-hover-success mb-1 font-size-lg"
                              >
                                {val?.email}
                              </a>
                            </span>
                          </div>
                        </td>
                        <td>
                          <span className=" font-weight-bold d-block text-center  ">
                            {val?.subject}
                          </span>
                        </td>
                        <td style={{ width: "400px" }}>
                          {val?.message.length > 70 ? (
                            <>
                              <span
                                className="text-muted font-weight-bold d-block text-center"
                                style={{
                                  maxWidth: "400px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {val?.message.slice(0, 70)}..{" "}
                                <span
                                  className="text-info  font-weight-bold cursor-pointer"
                                  onClick={() => {
                                    setData(val?.message);
                                    setShow(true);
                                  }}
                                >
                                  Read More
                                </span>{" "}
                              </span>
                            </>
                          ) : (
                            <span className="text-muted font-weight-bold d-block text-center">
                              {val?.message}
                            </span>
                          )}
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
                            <span className="svg-icon menu-icon text-warning">
                              {val?.status === true ? (
                                <img
                                  src={toAbsoluteUrl(
                                    "/media/svg/social-icons/checked.png"
                                  )}
                                  alt=""
                                />
                              ) : (
                                "Pending"
                              )}
                            </span>
                          </span>
                        </td>
                        <td className="text-center d-flex pr-0 justify-content-right ">
                          <div className="p-1">
                            {" "}
                            <a
                              href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${val?.email}`}
                              target="_blank"
                            >
                              <button className="btn btn-light-info  btn-sm font-weight-bolder font-size-sm mx-1">
                                Respond
                              </button>
                            </a>
                          </div>
                          <div className="p-1">
                            {" "}
                            {val?.status === true ? (
                              <button className="btn btn-light-primary  btn-sm font-weight-bolder font-size-sm mx-1">
                                Approved
                              </button>
                            ) : (
                              <button
                                className="btn btn-light-success  btn-sm font-weight-bolder font-size-sm mx-1"
                                onClick={() => handleUpdate(val)}
                              >
                                Approve
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* end::Table */}
            </div>
            {show && (
              <ViewMessage
                open={show}
                data={data}
                close={() => setShow(false)}
              />
            )}
          </div>{" "}
          <PageChanger>
            <PagedDiv>
              <ul>
                {page > 1 && (
                  <li onClick={() => setPage(page - 1)}>Previous</li>
                )}
                {contactUsData?.length <= 20 && page > 1 && (
                  <li onClick={() => setPage(page - 1)}>
                    {contactUsData?.length <= 20 ? page - 1 : ""}
                  </li>
                )}
                <li className="active">{page}</li>
                {contactUsData?.length >= 20 && (
                  <li onClick={() => setPage(page + 1)}>
                    {contactUsData?.length >= 20 ? page + 1 : ""}
                  </li>
                )}
                {contactUsData?.length >= 20 && (
                  <li onClick={() => setPage(page + 1)}>
                    {contactUsData?.length >= 20 ? "Next" : ""}
                  </li>
                )}
              </ul>
            </PagedDiv>
          </PageChanger>
          {/* end::Body */}
        </div>
        {/* end::Advance Table Widget 9 */}
      </>
    );
  } else {
    return (
      <div className={`card card-custom `}>
        <NoData>NO DATA FOUND</NoData>
      </div>
    );
  }
}
