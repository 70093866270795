/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";

import { Link } from "react-router-dom";

import { dashboard } from "../../../../app/modules/Auth/_redux/authCrud";
import { toast } from "react-toastify";
import styled from "styled-components";

export function MixedWidget1({ className }) {
  const uiService = useHtmlClassService();
  // const [toggle, setToggle] = useState("Daily");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getDashbaordListing = async () => {
    enableLoading();
    let res = await dashboard();
    if (res.status === 200) {
      // enableLoading();
      disableLoading();
      setData(res);
      // console.log(res);
    } else {
      toast.error(res.message);
      disableLoading();
    }
  };

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    getDashbaordListing();
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }
    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutProps]);

  return (
    <div className={`card card-custom bg-gray-100 ${className} `}>
      {/* Header */}
      {/* <div className="card-header border-0 bg-danger py-5"> */}
      {/* <h3 className="card-title font-weight-bolder text-white">Sales Stat</h3> */}
      {/* <div className="card-toolbar text-white"> */}
      {/* <button className="btn btn-light-danger"> */}
      {/* Total Sales */}
      {/* <span className="text-dark"> */}
      {/* Amount will be shown in this area */}
      {/* </span> */}
      {/* </button> */}

      {/* </div> */}
      {/* </div> */}
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
        // id="kt_mixed_widget_1_chart"
        // className="card-rounded-bottom bg-danger"
        // style={{ height: "200px" }}
        ></div>

        {/* Stat */}
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gap: "20px",
            padding: "5%",
          }}
        >
          {/* <div className="row m-0"> */}
          <div className="col bg-light-warning px-6 py-7 rounded-xl mr-7 mb-7">
            <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              ></SVG>
              {/* {
                  (loading) ?
                    (
                      <LoadingAnimation>
                        <div className="wave">
                          <span className="dot"></span>
                          <span className="dot"></span>
                          <span className="dot"></span>
                        </div>
                      </LoadingAnimation>
                    ) : */}
              <span
                className="p-0 text-warning  ml-10"
                style={{ fontSize: "16px" }}
              >
                {data?.totalUserCount}
              </span>
              {/* } */}
            </span>

            <Link
              to="/user-profile"
              className="text-warning font-weight-bold font-size-h6 mt-2"
            >
              Total Users
            </Link>
          </div>
          <div className="col bg-light-primary px-6 py-7 rounded-xl  mb-7">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
              ></SVG>
              <span
                className="p-0 text-primary  ml-10"
                style={{ fontSize: "16px" }}
              >
                {data?.totalProfileCount}
              </span>
            </span>

            <span className="d-flex">
              <Link
                to="/posted-profiles"
                className="text-primary font-weight-bold font-size-h6 mr-auto "
              >
                Business Profile
              </Link>
            </span>
          </div>
          <div className="col bg-light-danger px-6 py-7 rounded-xl mr-7 mb-7">
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              ></SVG>
              <span
                className="p-0 text-danger  ml-10"
                style={{ fontSize: "16px" }}
              >
                {data?.totalDeactiveProfileCount}
              </span>
              <span className="d-flex">
                <Link
                  to="/deactivate-profiles"
                  className="text-danger font-weight-bold font-size-h6 mt-2"
                >
                  Deactive Profiles
                </Link>
              </span>
            </span>
          </div>
          <div className="col bg-light-info px-6 py-7 rounded-xl  mb-7">
            <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
              ></SVG>
              <span
                className="p-0 text-info  ml-10"
                style={{ fontSize: "16px", bottom: "0" }}
              >
                {data?.totalCategoryCount}
              </span>
            </span>
            <span className="d-flex">
              <Link
                to="/categories"
                className="text-info font-weight-bold font-size-h6 mr-auto "
              >
                category
              </Link>
            </span>
          </div>
        </div>
      </div>

      {/* Resize */}
      <div className="resize-triggers">
        <div className="expand-trigger">
          <div style={{ width: "411px", height: "461px" }} />
        </div>
        <div className="contract-trigger" />
      </div>
    </div>
    // </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function(val) {
          return "$" + val + " thousands";
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  };
  return options;
}
// eslint-disable-next-line no-unused-vars
const LoadingAnimation = styled.div`
  background-color: transparent;
  .wave {
    ${"" /* position: relative; */}
    background-color: transparent;
    /* margin-top:50vh; */
    text-align: center;
    width: 100px;
    height: 20px;
    ${"" /* margin-top: 5px; */}
    margin-left: auto;
    margin-right: auto;
  }
  .dot {
    display: inline-block;
    width: 10px;
    height: 4px;
    border-radius: 50%;
    margin-right: 3px;
    ${"" /* padding-top: 10px; */}
    background: #303131;
    animation: wave 1.3s linear infinite;
  }

  :nth-child(2) {
    animation-delay: -1.1s;
  }

  :nth-child(3) {
    animation-delay: -0.9s;
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-10px);
    }
  }

  ${"" /* .lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */}
`;
