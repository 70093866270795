/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { PageChanger } from "../../../GobalStyled";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { deleteReport, ReportListing } from "../Auth/_redux/authCrud";
import DeleteBlock from "../Modals/DeleteBlock";

export default function Report() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(3);
  const [page, setPage] = useState(1);
  const [modalLoader, setModalLoder] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [parameter, setParameter] = useState({});
  const history = useHistory();
  const location = useLocation();

  const sortData = (data) => {
    let sort = data.sort((a, b) => {
      return a.status - b.status;
    });
    setData(sort);
  };
  const getbtnClasses = (status) => {
    if (status === 3) {
      return "danger";
    } else if (status === 2) {
      return "dark";
    }
    return "success";
  };
  const handleDelete = async () => {
    setModalLoder(true);
    if (parameter.name === "Delete") {
      let res = await deleteReport(parameter.id);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        setLoading(false);
        getAllReportListing();
        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };
  // const Delete = (id) => {
  //   setOpenModal(true);
  //   let para = {
  //     name: "Delete",
  //     body: "this Report",
  //     id: id,
  //   };
  //   setParameter(para);
  // };

  const getAllReportListing = async () => {
    setLoading(true);
    setFilter(3);
    //filter to list all reports as per the actions taken on them by admin
    let res = await ReportListing();
    if (res.status === 200) {
      // console.log(res.data, "resssssssss--------");
      sortData(res.data);

      setLoading(false);
    } else {
      setLoading(false);
      toast.error();
    }
  };

  useEffect(() => {
    getAllReportListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page]);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <div className={`card card-custom `}>
      {/* Head */}

      {/* Body */}
      <div className="card-body pt-3 pb-2 my-4">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  {/* <th className="pl-2" style={{ minWidth: "100px" }}></th> */}
                  <th className="pl-2" style={{ minWidth: "150px" }}>
                    Reported Profile
                  </th>
                  <th className="pl-2" style={{ minWidth: "150px" }}>
                    Reported By
                  </th>
                  {/* <th style={{ minWidth: "200px" }}>Reason</th> */}
                  <th style={{ minWidth: "40px" }}>Count</th>
                  <th style={{ minWidth: "20px" }}>Status</th>
                  <th className="text-center" style={{ minWidth: "20px" }}>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr>
                    {/* <td className="pl-0 py-4">
                      <div className="symbol symbol-50 symbol-light mr-1">
                        <span className="symbol-label">
                          {item?.user.profile_picture ? (
                            <ListingProfilePictures
                              src={item?.user.profile_picture}

                              alt="Profile"
                            />
                          ) : item?.user.gender === "male" ? (
                            <ListingProfilePictures
                              src="../media/svg/avatars/001-boy.svg"
                              alt="Profile"
                            />
                          ) : (
                            <ListingProfilePictures
                              src="../media/svg/avatars/002-girl.svg"
                              alt="Profile"
                            />
                          )}
                        </span>
                      </div>
                    </td> */}
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div
                          className="mx-2"
                          onClick={() =>
                            history.push("/user-details", {
                              id: item?._id,
                              path: location?.pathname,
                            })
                          }
                        >
                          <a
                            href
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg "
                          >
                            {item?.first_name} {item?.last_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          <a
                            href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${item?.reportedByUserData[0]?.email}`}
                            target="_blank"
                            title="Tap to send e-mail"
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg "
                          >
                            {item?.reportedByUserData[0]?.first_name}{" "}
                            {item?.reportedByUserData[0]?.last_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item?.count}
                      </span>
                    </td>

                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        <span className="svg-icon menu-icon text-warning">
                          {item?.profile_status === false ? (
                            <img
                              src={toAbsoluteUrl(
                                "/media/svg/social-icons/checked.png"
                              )}
                              alt=""
                            />
                          ) : (
                            "Pending"
                          )}
                        </span>
                      </span>
                    </td>
                    <td className="pr-0 text-center">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        <button
                          className={`btn btn-light-${getbtnClasses(
                            item.status
                          )} font-weight-bolder font-size-sm mx-1`}
                          onClick={() =>
                            history.push("/view-report", {
                              data: item,
                              path: location.pathname,
                            })
                          }
                        >
                          View
                        </button>

                        {/* <button
                          className="btn btn-light-danger font-weight-bolder font-size-sm mx-1"
                          onClick={() => Delete(item?._id)}
                        >
                          Delete
                        </button> */}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openModal && (
        <DeleteBlock
          open={openModal}
          close={() => setOpenModal(false)}
          confirmSumbit={handleDelete}
          parameter={parameter}
          loading={modalLoader}
        />
      )}
      {/*
      

      {show && (
        <ChallengeView
          open={show}
          id={challengeID}
          close={() => setShow(false)}
        />
      )} */}

      <PageChanger>
        {page > 1 && (
          <span
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            Go Back
          </span>
        )}
        {/* <span>{page}</span> */}
        {data.length > 20 && (
          <span className="active" onClick={() => setPage(page + 1)}>
            See More
          </span>
        )}
      </PageChanger>
    </div>
  );
}
