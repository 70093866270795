/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  activeDeactivePostProfile,
  deactiveProfileListing,
  searchDeactiveprofile,
  // eslint-disable-next-line no-unused-vars
  searchprofile,
} from "../../../../app/modules/Auth/_redux/authCrud";
import DeleteBlock from "../../../../app/modules/Modals/DeleteBlock";
import ViewUserModal from "../../../../app/modules/UserProfile/components/ViewUserModal";
import {
  ListingProfilePictures,
  NoData,
  // PageChanger,
  // PagedDiv,
} from "../../../../GobalStyled";

export function AdvanceTablesWidget3({ className }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [parameter, setParameter] = useState({});
  const [data, setData] = useState([]);
  const history = useHistory();
  const baseUrl = "https://d17am3tdlggecz.cloudfront.net/";
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const getSearchWord = async (word) => {
    let s = word.target.value;
    // console.log({ searchKeyword: word.target.value }, "data??????");
    if (s) {
      await searchDeactiveprofile(word.target.value)
        .then(({ data }) => {
          console.log(data, "data--------------");
          setUserData(data);
        })
        .catch(() => {
          disableLoading();
        });
    }
    if (!s) {
      setUserData([]);
      getUserListing();
    }
  };

  const getUserListing = () => {
    enableLoading();
    deactiveProfileListing()
      .then(({ data }) => {
        setUserData(data);
        console.log(data);
        disableLoading();
        // dispatch(loginAdmin(data))
      })
      .catch(() => {
        disableLoading();
      });
  };
  const handleActive = (id) => {
    setOpenDelete(true);
    let para = {
      name: "Activate",
      body: "this user",
      id: id,
    };
    console.log(para);
    setParameter(para);
  };

  const ActiveDeactive = async () => {
    setModalLoading(true);
    const req = {
      profileId: parameter.id,
      profileStatus: true,
    };
    let res = await activeDeactivePostProfile(req);
    if (res.status === 200) {
      setModalLoading(false);
      setOpenDelete(false);
      toast.info(res.message);
      getUserListing();
    } else {
      setModalLoading(false);
      setOpenDelete(false);
      toast.info(res.message);
    }
  };

  useEffect(() => {
    getUserListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ minHeight: "65vh" }}
      >
        {openDelete && (
          <DeleteBlock
            open={openDelete}
            close={() => setOpenDelete(false)}
            confirmSumbit={ActiveDeactive}
            parameter={parameter}
            loading={modalLoading}
          />
        )}

        <div className="card-header border-0 pt-0">
          <h3 className="card-title align-items-start flex-column">
            {/* <span className="card-label font-weight-bolder text-dark">
            New Arrivals
          </span> */}
            <span className="text-muted mt-3 font-weight-bold font-size-sm"></span>
          </h3>

          <input
            name="search"
            type="search"
            placeholder="Search"
            onChange={getSearchWord}
            className="form-control w-25  ml-auto mt-5"
          />
        </div>
        {userData?.length > 0 ? (
          userData.map((ele) => (
            <div className="card-body pt-3 pb-0">
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th className="p-0" style={{ width: "60px" }} />
                      <th className="p-0" style={{ minWidth: "250px" }} />
                      <th className="p-0" style={{ minWidth: "100px" }} />
                      {/* <th className="p-0" style={{ minWidth: "125px" }} /> */}
                      {/* <th className="p-0" style={{ minWidth: "110px" }} /> */}
                      <th className="p-0" style={{ minWidth: "150px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pl-0 py-4">
                        <div className="symbol symbol-50 symbol-light mr-1">
                          <span className="symbol-label">
                            {ele.postProfile_picture.length !== 0 ? (
                              <ListingProfilePictures
                                src={baseUrl + ele.postProfile_picture[0]}
                                alt="Profile"
                              />
                            ) : (
                              <ListingProfilePictures
                                src="../media/svg/avatars/001-boy.svg"
                                alt="Profile"
                              />
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="pl-0">
                        <span
                          // onClick={() =>
                          //   history.push("/user-details", { id: ele._id })
                          // }
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                        >
                          {ele.first_name + " " + ele.last_name}
                        </span>
                        <div>
                          <span className="font-weight-bolder">Email: </span>
                          <a
                            className="text-muted font-weight-bold text-hover-primary"
                            href="#"
                          >
                            {ele?.userDetails[0].email}
                          </a>
                        </div>
                      </td>

                      <td className="text-right"></td>

                      <td className="text-right pr-0">
                        <button
                          className="btn btn-light-success btn-sm font-weight-bolder font-size-sm mx-1"
                          onClick={() => {
                            history.push("/user-details", { id: ele?._id });
                            setData(ele?._id);
                          }}
                        >
                          View
                        </button>
                        <button
                          className="btn btn-light-danger btn-sm font-weight-bolder font-size-sm mx-1"
                          onClick={() => handleActive(ele?._id)}
                        >
                          Activate
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))
        ) : (
          <NoData>DATA NOT FOUND</NoData>
        )}
        {/* <PageChanger>
          <PagedDiv>
            <ul>
              {page > 1 && <li onClick={() => setPage(page - 1)}>Previous</li>}
              {userData?.length <= 20 && page > 1 && (
                <li onClick={() => setPage(page - 1)}>
                  {userData?.length <= 20 ? page - 1 : ""}
                </li>
              )}
              <li className="active">{page}</li>
              {userData?.length >= 20 && (
                <li onClick={() => setPage(page + 1)}>
                  {userData?.length >= 20 ? page + 1 : ""}
                </li>
              )}
              {userData?.length >= 20 && (
                <li onClick={() => setPage(page + 1)}>
                  {userData?.length >= 20 ? "Next" : ""}
                </li>
              )}
            </ul>
          </PagedDiv>
        </PageChanger> */}
        {show && (
          <ViewUserModal open={show} data={data} close={() => setShow(false)} />
        )}
      </div>
    </>
  );
}
