import moment from "moment/moment";
import React from "react";
// import { useHistory } from "react-router";
import { ListingProfilePictures } from "../../../../GobalStyled";

const ReportInfo = (props) => {
  const data = props.data;
  console.log(props.data);
  const reportData = props.reportData;
  const baseurl = "https://d17am3tdlggecz.cloudfront.net/";
  // const history = useHistory();
  const createDate = (date) => {
    let update = new Date(date);
    return moment(update).format("DD/MM/YYYY");
  };
  return (
    <>
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-borderless table-vertical-center">
            <thead>
              <tr
                style={{
                  backgroundColor: "#ededed",
                  borderRadius: "2%",
                }}
              >
                <th className=" text-center" style={{ width: "60px" }}></th>
                <th className="" style={{ minWidth: "100x" }}>
                  UserName
                </th>
                <th className=" text-center" style={{ minWidth: "100px" }}>
                  Comment
                </th>
                <th className=" text-center" style={{ minWidth: "100px" }}>
                  Note
                </th>
                {/* <th className="p-0" style={{ minWidth: "125px" }} /> */}
                {/* <th className="p-0" style={{ minWidth: "110px" }} /> */}
                <th className="text-center" style={{ minWidth: "100px" }}>
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((val, id) => (
                <tr>
                  <td className="pl-2 py-4">
                    <div className="symbol symbol-50 symbol-light mr-1">
                      <span className="symbol-label">
                        {val?.profile_picture ? (
                          val?.profile_picture !== "null" ? (
                            <ListingProfilePictures
                              src={baseurl + val?.profile_picture}
                              alt="Profile"
                            />
                          ) : (
                            <ListingProfilePictures
                              src="../media/svg/avatars/001-boy.svg"
                              alt="Profile"
                            />
                          )
                        ) : (
                          <ListingProfilePictures
                            src="../media/svg/avatars/001-boy.svg"
                            alt="Profile"
                          />
                        )}
                      </span>
                    </div>
                  </td>
                  <td className="pl-0">
                    <span
                      // onClick={() => {
                      //   history.push("/user-details", { id: val._id });
                      //   history.push("/user-details", { id: val._id });
                      // }}
                      className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                    >
                      {val.first_name + " " + val.last_name}
                    </span>
                  </td>

                  <td className="text-center">
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      {reportData[id]?.reportText}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="text-muted font-weight-500">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {reportData[id]?.note}
                      </span>
                    </span>
                  </td>

                  <td className="text-center pr-0 font-weight-bolder">
                    {createDate(reportData[id]?.created_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default ReportInfo;
