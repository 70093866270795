import React from "react";
import { AdvanceTablesWidget3 } from "../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget3";
export function BlockedUsers() {
  return (
    <div className="row">
      <div className="col-lg-12">
        <AdvanceTablesWidget3></AdvanceTablesWidget3>
      </div>
    </div>
  );
}
