import React from "react";
import { AdvanceTablesWidget11 } from "../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget11";

export function Categories() {
    return (
        <div className="row">
            <div className="col-lg-12">
                <AdvanceTablesWidget11 className="card-stretch gutter-b"></AdvanceTablesWidget11>
            </div>
        </div>
    );
}
