/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";

export function AdvanceTablesWidget10({ data }) {
  const baseurl = "https://d17am3tdlggecz.cloudfront.net/";

  const getType = (value) => {
    let val = value.split(".");
    let type = "img";
    // console.log(data, 'value');
    if (val?.length > 0) {
      if (val[1] === "mp4") {
        type = "mp4";
      }
    }
    return type;
  };
  const getDescription = (id) => {
    let text = "";

    switch (id) {
      case 0:
        if (data?.description_1) {
          text = data?.description_1;
        }
        break;
      case 1:
        if (data?.description_2) {
          text = data?.description_2;
        }
        break;
      case 2:
        if (data?.description_3) {
          text = data?.description_3;
        }
        break;
      case 3:
        if (data?.description_4) {
          text = data?.description_4;
        }
        break;
      case 4:
        if (data?.description_5) {
          text = data?.description_5;
        }
        break;
      default:
        text = "--";
    }
    if (text === "") {
      text = "--";
    }
    return text;
  };

  return (
    <div className={`card card-custom `}>
      {/* Head */}

      {/* Body */}
      <div className="card-body pt-4 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase ">
                  <th
                    className="pl-2  text-center "
                    style={{ minWidth: "350px" }}
                  >
                    <span className="text-dark-75">Media</span>
                  </th>
                  <th className="text-center" style={{ minWidth: "530px" }}>
                    <span className="text-dark-75">About</span>
                  </th>
                  {/* <th style={{ minWidth: "80px" }} />?   */}
                </tr>
              </thead>
              <tbody>
                {data?.postProfile_picture?.map((val, id) => {
                  return (
                    <tr>
                      <td className="pl-0 py-8">
                        <div className="d-flex align-items-center">
                          <div className="mx-auto">
                            {/* {console.log(baseurl + val)} */}
                            {val.length > 0 ? (
                              getType(val) === "img" ? (
                                <img
                                  style={{ height: "145px", width: "250px" }}
                                  src={`${baseurl + val}`}
                                  alt=""
                                />
                              ) : (
                                <video
                                  autoPlay
                                  muted
                                  loop={true}
                                  style={{ height: "145px", width: "250px" }}
                                  src={`${baseurl + val}`}
                                  alt=""
                                />
                              )
                            ) : null}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-dark-75  text-center font-weight-bolder d-block font-size-lg">
                          {getDescription(id)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <iframe
              style={{ border: "none" }}
              src={`https://maps.google.com/maps?q=${data?.lat},${data?.long}&output=embed`}
              width={"100%"}
              height={"250px"}
            ></iframe>
          </div>
          {/* {show && (
            <ViewChallenges
              open={show}
              id={challengeData}
              close={() => setShow(false)}
            />
          )} */}
        </div>
      </div>
    </div>
  );
}
