import React from "react";
import { AdvanceTablesWidget9 } from "../../../_metronic/_partials/widgets";

const PostProfiles = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <AdvanceTablesWidget9
          className={"card-stretch gutter-b"}
          class
        ></AdvanceTablesWidget9>
      </div>
    </div>
  );
};
export default PostProfiles;
