/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { categoryListing } from "../../../../app/modules/Auth/_redux/authCrud";

export function AdvanceTablesWidget11({ className }) {
  const history = useHistory();
  // const [clicked, setClicked] = useState(false)
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getCategoriesListing = () => {
    enableLoading();
    categoryListing(1)
      .then(({ data }) => {
        setCategories(data);
        console.log(data);
        disableLoading();
        // dispatch(loginAdmin(data))
      })
      .catch(() => {
        disableLoading();
      });
  };
  useEffect(() => {
    getCategoriesListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }
  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        {/* <div className="card-header border-0  d-flex justify-content-end">
                    <div className="card-header border-0 pt-4">
                        <Dropdown className="pt-2">
                            <Dropdown.Toggle id="dropdown-basic">
                                Location                          </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item >
                                    Current Location
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <input type='text' placeholder='search'></input>
                                </Dropdown.Item>
                                <Dropdown.Item >
                                    Recent Locations
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div> */}
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-3 pb-3">
          <div className="tab-content">
            {/* begin::Table */}
            <div className="table-responsive">
              <table className="table table-head-custom table-vertical-center table-head-bg table-borderless">
                <thead className="pb-4">
                  <tr className="text-left">
                    <th style={{ minWidth: "35%" }} className="pl-7">
                      <span className="text-dark-75">Category</span>
                    </th>
                    <th style={{ minWidth: "20%", textAlign: "center" }}>
                      Count
                    </th>
                    <th style={{ minWidth: "35%", textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categories?.map((val) => (
                    <tr>
                      <td className="pl-0 py-0">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-light mr-4 mt-3"></div>
                          <div>
                            <a
                              onClick={() =>
                                history.push("/category-profiles", {
                                  id: val?._id,
                                  category: val?.category_name,
                                })
                              }
                              className="text-dark-75 font-weight-bolder text-hover-success mb-1 font-size-lg"
                            >
                              {val?.category_name}
                            </a>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className="text-muted font-weight-bold d-block text-center  ">
                          {val?.count}
                        </span>
                      </td>
                      <td className="text-center pr-0">
                        <div>
                          <button
                            className="btn btn-light-success btn-right  btn-sm font-weight-bolder font-size-sm mx-1"
                            onClick={() =>
                              history.push("/category-profiles", {
                                id: val?._id,
                                category: val?.category_name,
                              })
                            }
                          >
                            View Profiles
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* end::Table */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}
