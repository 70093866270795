/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { Dropdown } from "react-bootstrap";
// import { AddButton } from "../../../../GobalStyled";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { profileLisitingByCat } from "../../../../app/modules/Auth/_redux/authCrud";
import { NoData } from "../../../../GobalStyled";

export function AdvanceTablesWidget12({ props }) {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  // const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false);
  // const baseUrl = 'https://d17am3tdlggecz.cloudfront.net/'
  const getPostProfileByCategory = () => {
    setLoading(true);
    let res = profileLisitingByCat(props.location?.state?.id);
    res.then((res) => {
      if (res.status === 200) {
        setData(res.data);
        console.log(res.data);
        setLoading(false);
      } else {
        setLoading(false);

        toast.error(res.message);
      }
    });
    // if (res.status === 200) {

    //   setLoading(false)
    //   console.log(res.data)
    // }
    // else {
    //   setLoading(false)
    //   toast.error(res.message)
    // }
  };
  useEffect(() => {
    getPostProfileByCategory();
  }, []);
  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }
  if (data.length > 0) {
    return (
      <>
        {/* begin::Advance Table Widget 9 */}
        <div className={`card card-custom `}>
          {/* begin::Header */}
          <div className="card-header border-0 py-0 d-flex justify-content-end ">
            <div className="card-header border-0 pt-2"></div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              {/* begin::Table */}
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead className="pb-4">
                    <tr className="text-left">
                      <th style={{ minWidth: "250px" }} className="pl-7">
                        <span >
                          Buisness Owner Name{" "}
                        </span>
                      </th>

                      <th style={{ minWidth: "120px" }}>Category</th>
                      <th style={{ minWidth: "100px" }}>Location</th>
                      {/* <th style={{ minWidth: "100px" }}>Profile Upload</th> */}
                      <th style={{ minWidth: "100px", textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((val) => (
                      <tr>
                        <td className="pl-0 py-0">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light mr-4 mt-3">
                              {/* <span className="symbol-label">
                              {val.postProfile_picture ?
                                <SVG src={baseUrl + val?.postProfile_picture[0]} alt="Profile" />
                                :
                                <SVG src="../media/svg/avatars/001-boy.svg" alt="Profile" />
                              }
                            </span> */}
                            </div>
                            <div>
                              <a
                                onClick={() =>
                                  history.push("/user-details", { id: val._id })
                                }
                                className="text-dark-75 font-weight-bolder text-hover-primary pl-2 mb-1 font-size-lg"
                              >
                                {val?.first_name}
                              </a>
                              <span className="text-muted font-weight-bold d-block"></span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {val?.category[0]?.category_name}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {val?.location_text}
                          </span>
                        </td>
                        <td className="text-center pr-0">
                          <div>
                            <button
                              className="btn btn-light-primary  btn-sm font-weight-bolder font-size-sm mx-1"
                              onClick={() =>
                                history.push("/user-details", {
                                  id: val?._id,
                                  path: location?.pathname,
                                })
                              }
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* end::Table */}
            </div>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Advance Table Widget 9 */}
      </>
    );
  } else {
    return (
      <div className={`card card-custom `}>
        <NoData>NO DATA FOUND</NoData>
      </div>
    );
  }
}
