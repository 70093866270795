import React from "react";
import { AdvanceTablesWidget13 } from "../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget13";

export function ContactUs() {
    return (
        <div className="row">
            <div className="col-lg-12">
                <AdvanceTablesWidget13 className="card-stretch gutter-b"></AdvanceTablesWidget13>
            </div>
        </div>
    );
}
