import React from "react";
import styled from "styled-components";
import { InfoLine, InfoValue, InfoWrapper } from "../../../GobalStyled";

export default function UserInfo({ data }) {
  return (
    <InfoWrapper>
      <div>
        <InfoLine>
          <InfoValue
            style={{ textAlign: "center", borderBottom: "2px solid grey" }}
          >
            First Name
          </InfoValue>
          <ValueLine> {data?.first_name ? data?.first_name : "--"}</ValueLine>
        </InfoLine>
        <InfoLine>
          <InfoValue
            style={{ textAlign: "center", borderBottom: "2px solid grey" }}
          >
            Last Name
          </InfoValue>
          <ValueLine>{data?.last_name ? data?.last_name : "--"}</ValueLine>
        </InfoLine>
        <InfoLine>
          <InfoValue
            style={{ textAlign: "center", borderBottom: "2px solid grey" }}
          >
            User Name
          </InfoValue>

          <ValueLine>{data?.user_name ? data?.user_name : "--"}</ValueLine>
          {/* <InfoLabel>Phone Number</InfoLabel> */}
        </InfoLine>
        <InfoLine>
          <InfoValue
            style={{ textAlign: "center", borderBottom: "2px solid grey" }}
          >
            {" "}
            Location
          </InfoValue>

          <ValueLine>
            {data?.location_text ? data?.location_text : "--"}
          </ValueLine>
        </InfoLine>
      </div>
    </InfoWrapper>
  );
}

export const ValueLine = styled.span`
  font-style: normal;
  font-size: 18px;
  width: 50%;
  letter-spacing: -0.04em;
  font-weight: 400;
  line-height: 24px;
  color: grey;
  text-align: left;
  border-bottom: 2px solid grey;
  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
  }

  img {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }
  img:hover {
    transform: scale(1.5);
  }
  @media screen and (max-width: 680px) {
    text-align: center;
  }
`;
