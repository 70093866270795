import styled from "styled-components";

export const PageInnerContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 0px 0px 16px 16px;
  min-height: 82vh;
`;

export const SingleLine = styled.div`
  background: #9aacb5;
  height: 1px;
  opacity: 0.5;
  width: 95%;
  margin: auto;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  // margin: 20px 0;
  padding: 15px 0;
  position: relative;
  border-bottom: 2px solid #999999;

  .active {
    color: red;
    font-weight: 500;
    font-size: 18px;

    &:before {
      position: absolute;
      transtion: all ease-in 0.3s;
      content: "";
      bottom: -2px;
      left: ${({ tab }) => tab === 1 && 0};
      right: ${({ tab }) => tab === 2 && 0};
      width: 50%;
      height: 2px;
      background: red;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    color: #000000;
  }
`;

export const UserProfileInfoInner = styled.div`
  display: flex;
  align-items: center;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #20b839;
    margin: 0 5px;
  }

  p {
    padding: 6px 10px;
    width: 68px;
    color: #fff;
    margin: 10px 0;
    background: #ff0000;
    border-radius: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
`;

export const ActionButtonForUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.03em;
  ${"" /* border-right: 2px solid #0004; */}
  color: #ffffff;

  .Remove {
    display: flex;
    align-items: center;
    height: 34px;
    background: #208573;
    border-radius: 30px;
    margin: 0 5px;
  }

  .Delete {
    height: 34px;
    display: flex;
    align-items: center;
    background: #ff1f00;
    border-radius: 30px;
    margin: 0 5px;
  }

  .Block {
    display: flex;
    align-items: center;
    height: 34px;
    background: #777777;
    border-radius: 30px;
    margin: 0 5px;
  }

  span {
    padding: 0 20px;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

  @media (max-width: 1288px) {
    padding: 20px;
  }
`;

export const UserFollowingDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  border-right: 2px solid #0004;
  @media (max-width: 1288px) {
    border-right: none;
  }

  p {
    margin: 0;
    padding: 0 10px;
    font-size: 17px;
    line-height: 21px;
    color: #161722;
    text-align: center;
  }
  span {
    padding: 0 10px;
    font-size: 13px;
    line-height: 16px;
    color: #86878b;
  }
`;

export const UserProfileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #0004;

  img {
    width: 90px;
    height: 90px;
    // padding: 0 10px 0 0;
    margin: 0 10px 0 0;
    border-radius: 50%;
    border: 1px solid grey;
  }

  h4 {
    margin: 0;
    // font-family: 'Roboto';

    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #161722;
  }
  span {
    // font-family: 'Roboto';

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    text-align: center;
    color: #ff0000;
  }
`;

export const UserInfoBox = styled.div`
  justify-content: "center";
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px 0;
  @media (max-width: 1288px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 748px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PageHeader = styled.div`
  display: flex;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.03em;
    margin: 0;
    color: #000000;
  }
  svg {
    cursor: pointer;
    font-size: 18px;
    padding: 5px 30px 0 0;
  }
`;

export const ActiveRecentUser = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  img {
    width: 34px;
    height: 34px;
  }

  span {
    margin: 0 0 0 10px;
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #000000;
    opacity: 0.6;
  }
`;

export const RecentUserInnner = styled.div`
  padding: 15px 18px;
`;

export const UserBoxInner = styled.div`
  padding: 30px;
`;

export const CardHeading = styled.div`
  padding: 24px 0;
  p {
    margin: 0;
    // font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #000000;
    opacity: 0.5;
    white-space: nowrap;
  }
  span {
    // font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #000000;
  }
`;

export const DashCard = styled.div`
  cursor: pointer;
  height: 92px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  img {
    width: 64px;
    height: 64px;
    padding: 14px 20px;
  }
`;

export const DashCardWrap = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  padding: 32px 0 0 0;
`;

export const SemiHeader = styled.p`
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #000000;
  margin: 0;
  padding: 8px 0;
  opacity: 0.5;
`;

export const OverviewBox = styled.div`
  width: 100%;
  // height: 230px;
`;

export const RecentUser = styled.div`
  width: 226px;
  background: #ffffff;
  border-radius: 0px 0px 16px 0px;
  margin: 8px 24px 24px 0px;
`;

export const VideoBox = styled.div`
  width: 775px;
  background: #ffff;
  border-radius: 0px 0px 0px 16px;
  margin: 12px 12px 24px 0;
`;

export const UserBox = styled.div`
  width: 775px;
  margin: 8px 12px 12px 0;
  background: #ffffff;
  border-radius: 0px;
`;

export const DashHeaderBox = styled.div`
  width: calc(100% - 35px);
  margin: 24px 24px 8px 0px;
  background: #ffffff;
  border-radius: 16px 16px 0px 0px;
`;

export const InnerContentBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const VideoContent = styled.div`
  padding: 0 20px;
  h4 {
    margin: 0;
    padding: 3px;
    // font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #000000;
  }
  p {
    margin: 0;
    padding: 3px;

    // font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #000000;
  }

  h6 {
    margin: 0;
    padding: 3px;
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #ff0000;
  }

  svg {
    color: #ff5050;
    margin: 0 5px;
  }

  span {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #000000;
    opacity: 0.6;
    margin: 0px 5px 10px 0;
  }
`;

export const VideoThumb = styled.div`
  width: 96px;
  height: 96px;
`;

export const VideoBoxWrap = styled.div`
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 8px;
  // box-shadow: 1px 1px 3px #f1f1f1;
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
`;

export const VideoInnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
`;

export const VideoWrapper = styled.div`
  width: 100%;
`;

export const AddButton = styled.button`
  border-style: none;
  background: #20b839d1;
  border-radius: 7px;
  // font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #ffffffc7;

  white-space: nowrap;
  padding: 10px;
  cursor: pointer;
  transition: background;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;

  :hover {
    background: #20b839;
    color: #ffffff;
  }
`;

export const ModalWrapper = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    font-size: 45px;
  }
`;

// export const SaveButton = styled(Button)`
//   width: 129px;
//   height: 48px;
//   background: #292929;
//   border-radius: 40px;
// font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 17px;
//   letter-spacing: -0.04em;
//   color: #ffffff;
//   margin: 20px 10px 10px 10px;

//   :hover {
//     color: black;
//     font-size: 15px;
//     border: 1px solid black;
//   }
// `;

// export const CancelButton = styled(Button)`
//   width: 129px;
//   height: 48px;
//   background: #ffffff;
//   border: 1px solid #e6e6f1;
//   box-sizing: border-box;
//   border-radius: 40px;
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 17px;
//   letter-spacing: -0.04em;
//   color: #000000;
//   margin: 20px 10px 10px 10px;
//   :hover {
//     border: 1px solid black;
//   }
// `;

export const Line = styled.span`
  width: 1px;
  background: #0004;
`;

export const InfoWrapper = styled.div`
  padding: 20px 30px;
  display: flex;

  div {
    width: 100%;
    margin: 0 20px 0 20px;
  }

  @media (max-width: 682px) {
    flex-direction: column;
    padding-top: 10px;
    padding-left: 0;
  }
`;

export const InfoValue = styled.span`
  // font-family: "Inter";
  font-style: normal;
  font-size: 18px;
  width: 50%;
  letter-spacing: -0.04em;
  font-weight: 500;
  line-height: 24px;
  color: #000000;

  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
  }

  img {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }
  img:hover {
    transform: scale(1.5);
  }
`;

export const InfoLabel = styled.label`
  width: 50%;
  // font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  align-items: start;
`;

export const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  @media (max-width: 682px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e6e6f1;
  box-sizing: border-box;
  border-radius: 37px;
  // margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;

  ::placeholder {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #acacac;
  }
`;

export const NoData = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export const ListingProfilePictures = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;
export const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  div {
    position: relative;
  }
  img {
    border-radius: 15px;
    boxshadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    height: 100px;
    width: 100px;
  }
  span {
    position: absolute;
    left: 85px;
    right: 0;
    top: 0px;
    width: 20px;
    height: 20px;
    background: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 12px;
    z-index: 1;
    cursor: pointer;
  }
`;

export const PageChanger = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
  cursor: pointer;
  span {
    border-radius: 15px;
    background-color: #ddd;
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }
  span.active {
    background-color: #ddd;
    color: black;
  }

  span.active:hover {
    background-color: #4caf50;
    color: white;
  }

  span:hover:not(active) {
    background-color: #4caf50;
  }
`;
export const PagedDiv = styled.div`
  display: inline-block;
  li {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    list-style-type: none;
    border: 1px solid #ddd;
  }

  li.active {
    background-color: #222d32;
    color: white;
    border: 1px solid #222d32;
  }

  li:hover:not(.active) {
    background-color: #ddd;
  }

  li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  li:last-child {
     border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

// {clicked ? (<Listed>
//   <button
//     className="btn btn-light-danger  btn-sm font-weight-bolder font-size-sm mx-1"

//   >1</button>
//   <button
//     className="btn btn-light-danger  btn-sm font-weight-bolder font-size-sm mx-1"

//   >2</button>
//   <button
//     className="btn btn-light-danger  btn-sm font-weight-bolder font-size-sm mx-1"

//   >3</button>
// </Listed>)
//   :
//   <button
//     className="btn btn-light-danger  btn-sm font-weight-bolder font-size-sm mx-1"
//     onClick={() => setClicked(true)}
//   >...</button>}
