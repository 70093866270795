import React, { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  InfoLabel,
  InfoLine,
  InfoValue,
  InfoWrapper,
} from "../../../../GobalStyled";

// import { ViewChallenge } from "../Auth/_redux/authCrud";

const style = {
  position: "absolute",
  right: "-20px",
  top: "-20px",
  width: "50px",
  height: "50px",
  background: "red",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#ffffff",
  fontSize: "28px",
  zIndex: "1",
  cursor: "pointer",
};

export default function ViewUserModal({ open, close, data }) {
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState("");
  function getUserLocation(latitude, longitude) {
    setLoading(true);
    fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    )
      .then((resolve) => resolve.json())
      .then((json) => {
        setLoading(false);
        setLocation(json.address);
        // console.log(json);
      });
  }
  useEffect(() => {
    getUserLocation(data?.user_lat, data?.user_long);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      size="md"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          User Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="LoaderWrapper">
            <div className="BackgroundLoader" />
          </div>
        ) : (
          <InfoWrapper>
            <div>
              <InfoLine>
                <InfoLabel>First Name</InfoLabel>
                <InfoLabel>Last Name</InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoValue>{data?.first_name} </InfoValue>
                <InfoValue>{data?.last_name}</InfoValue>
              </InfoLine>

              <InfoLine>
                <InfoLabel>Address</InfoLabel>
                <InfoLabel>User Status</InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoValue>
                  {!location?.man_made
                    ? `${location?.state},${location?.country}`
                    : "--"}
                </InfoValue>
                <InfoValue>
                  {data?.notification ? "Active" : "Inactive"}
                </InfoValue>
              </InfoLine>

              <InfoLine>
                <InfoLabel>Email</InfoLabel>
              </InfoLine>
              <InfoLine>
                <InfoValue>{data?.email}</InfoValue>
              </InfoLine>
            </div>
          </InfoWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
}
