import React from "react";
import { Modal } from "react-bootstrap";
import { InfoWrapper } from "../../../../GobalStyled";
const style = {
  position: "absolute",
  right: "-20px",
  top: "-20px",
  width: "50px",
  height: "50px",
  background: "red",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#ffffff",
  fontSize: "28px",
  zIndex: "1",
  cursor: "pointer",
};
export const ViewMessage = ({ open, close, data }) => {
  const loading = false;

  return (
    <Modal
      show={open}
      onHide={close}
      dialogClassName="modal-120w"
      size="md"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="LoaderWrapper">
            <div className="BackgroundLoader" />
          </div>
        ) : (
          <InfoWrapper>
            <div>
              <span className="text-muted font-weight-bold d-block text-center">
                {data}
              </span>
            </div>
          </InfoWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
};
