import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import ViewProfile from "./modules/UserProfile/ViewProfile";
import AccountInformation from "./modules/UserProfile/AccountInformation";
import { CategoriesViewProfile } from "./modules/Category/CategoriesViewProfiles";
import Report from "./modules/Report/Report";
import ViewReport from "./modules/Report/ViewReport";

import PostProfiles from "./modules/PostProfiles/PostProfiles";
import { Categories } from "./modules/Category/Categories";
import { BlockedUsers } from "./modules/BlockedUsers/BlockedUsers";
import { ContactUs } from "./modules/ContactUs/Contact";
// import { AddCategory } from "./modules/Category/AddCategory";
const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/user-details" component={ViewProfile} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        {/* <Route path="/service-provider" component={UserProfilepage} /> */}
        <Route path="/reports" component={Report} />
        <Route path="/posted-profiles" component={PostProfiles} />
        <Route path="/view-report" component={ViewReport} />
        <Route path="/categories" component={Categories} />
        {/* <Route path="/add-category" component={AddCategory} /> */}

        <Route path="/AccountInformation" component={AccountInformation} />
        <Route path="/deactivate-profiles" component={BlockedUsers} />
        <Route path="/category-profiles" component={CategoriesViewProfile} />
        <Route path="/contact" component={ContactUs} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
