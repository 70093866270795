/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
// import SVG from "react-inlinesvg";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  categoryListing,
  profileLisitingAllCat,
  profileLisitingByCat,
  searchprofile,
} from "../../../../app/modules/Auth/_redux/authCrud";
// import { AddButton } from "../../../../GobalStyled";
// import { toAbsoluteUrl } from "../../../_helpers";

export function AdvanceTablesWidget9({ className }) {
  const history = useHistory();
  const location = useLocation();
  const [postData, setPostData] = useState([]);
  const [categoryDrpDwn, setCategoryDrpDwn] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [clicked, setClicked] = useState(false)
  const [toggle, setToggle] = useState("All");
  // const [filter, setFilter] = useState(3);
  // const [page, setPage] = useState(1);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getSearchWord = async (word) => {
    let s = word.target.value;
    console.log({ searchKeyword: word.target.value }, " ===== data??????");
    if (s) {
      await searchprofile(word.target.value)
        .then(({ data }) => {
          setToggle("All");
          console.log(data, "data--------------");
          setPostData(data);
        })
        .catch(() => {
          disableLoading();
        });
    }
    if (!s) {
      setToggle("All");
      setPostData([]);
      getAllCategories();
    }
  };

  // const getPostProfileListing = async () => {
  //   enableLoading();
  //   let res = await postProfileListing(1);
  //   if (res.status === 200) {
  //     setPostData(res.data);
  //     disableLoading();
  //   } else {
  //     toast.error(res.message);
  //     console.log(res.message);
  //     disableLoading();
  //   }
  // };
  const getCategoryListing = async () => {
    let res = await categoryListing(1);
    if (res.status === 200) {
      setCategoryDrpDwn(res.data);
    } else {
      toast.error(res.message);
    }
  };
  const getCategoryListingById = async (id) => {
    enableLoading();
    let res = await profileLisitingByCat(id);
    if (res.status === 200) {
      setPostData(res.data);
      disableLoading();
    } else {
      disableLoading();
      toast.error(res.message);
    }
  };
  const getAllCategories = async () => {
    enableLoading();
    let res = await profileLisitingAllCat();
    if (res.status === 200) {
      setPostData(res.data);
      disableLoading();
    } else {
      disableLoading();
      toast.error(res.message);
    }
  };
  useEffect(() => {
    getCategoryListing();
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <div>
      {/* begin::Advance Table Widget 9 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}

        <div className="card-header border-0 py-0 d-flex justify-content-end p-2 ">
          <div className="card-header border-0 pl-5 pt-2 pb-0 pr-0">
            <Dropdown className="pl-5">
              <Dropdown.Toggle id="dropdown-basic">{toggle}</Dropdown.Toggle>
              <Dropdown.Menu
                style={{ height: "250px", overflowY: "scroll", zIndex: "0" }}
              >
                <Dropdown.Item
                  onClick={() => {
                    getAllCategories();
                    setToggle("All");
                  }}
                >
                  All
                </Dropdown.Item>
                {categoryDrpDwn.map((value) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        getCategoryListingById(value?._id);
                        setToggle(value?.category_name);
                      }}
                    >
                      {`${value?.category_name}(${value?.count})`}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <input
            name="search"
            type="search"
            placeholder="Search"
            onChange={getSearchWord}
            className="form-control w-25  ml-auto "
          />
        </div>

        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-0 pb-3">
          <div className="tab-content">
            {/* begin::Table */}
            <div className="table-responsive">
              <table className="table table-head-custom table-vertical-center table-head-bg table-borderless">
                <thead className="pb-4">
                  <tr className="text-left">
                    <th style={{ minWidth: "30%" }} className="pl-7">
                      <span className="text-dark-75">Name</span>
                    </th>

                    <th style={{ minWidth: "20%" }}>Category</th>
                    <th style={{ minWidth: "100px", maxWidth: "200px" }}>
                      Location
                    </th>
                    <th style={{ minWidth: "20%", textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {postData.length > 0 &&
                    postData?.map((val) => {
                      return (
                        <tr style={{ width: "100%", overflow: "scroll" }}>
                          <td className="pl-0 py-0">
                            <div className="d-flex align-items-center">
                              {/* <div className="symbol symbol-50 symbol-light mr-4 mt-3">
                            <span className="symbol-label">
                              <SVG
                                className="h-75 align-self-end"
                                src={toAbsoluteUrl(
                                  "/media/svg/avatars/001-boy.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </div> */}
                              <div>
                                <a
                                  onClick={() =>
                                    history.push("/user-details", {
                                      id: val._id,
                                    })
                                  }
                                  className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg pl-2"
                                >
                                  {`${val.first_name} ${val.last_name}`}
                                </a>
                                <div>
                                  <a
                                    className="text-muted font-weight-bold text-hover-primary pl-2"
                                    href="#"
                                  >
                                    {val?.user_name}
                                  </a>
                                </div>
                                <span className="text-muted font-weight-bold d-block"></span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {val?.category
                                ? val?.category[0]?.category_name
                                : "ALL"}
                            </span>
                          </td>
                          <td style={{ maxWidth: "190px" }}>
                            <span className="text-dark-75 font-weight-bolder  font-size-lg">
                              {val?.location_text}
                            </span>
                          </td>

                          <td className="text-center pr-0">
                            <div>
                              <button
                                className="btn btn-light-primary  btn-sm font-weight-bolder font-size-sm mx-1"
                                onClick={() =>
                                  history.push("/user-details", {
                                    id: val?._id,
                                    path: location?.pathname,
                                  })
                                }
                              >
                                View
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {/* end::Table */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </div>
  );
}
