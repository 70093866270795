import axios from "axios";
import { deleteApi, getApi, postApi } from "../../../Services/ApiMethod";
export const REGISTER_URL = "api/auth/register";
export const LOGIN_URL = "adminLogin";
export const FORGOT = "forgetPassword";
export const CHANGE_PASSWORD = "changePassword";
export const UPDATE_ADMINPROFILE = "updateAdminProfile";
export const UserList = "getUserListing";
export const filterListing = "filterBlockedUnblockedListing";
export const CATEGORY_LIST = "getCategoryWithAvailableProfileCount";
export const SEARCH_USER = "searchUserByName";
export const SEARCH_PROFILE = "searchProfileByName";
export const SEARCH_DEACTIVE_PROFILE = "searchDeactiveProfileByName";

export const UserDetails = "viewUser";
export const VIEW_POST_PROFILE = "viewPostProfile";
export const ACTIVE_DEACTIVE_POSTPROFILE = "activateDeactivateProfileByAdmin";
export const USER_DELETE = "deleteProfile";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const BLOCK_UNBLOCK_BY_ADMIN = "blockUnblockUserByAdmin";
export const UPDATE_CONTACT_STATUS = "updateContactUsStatus";
export const REPORT_LISTING = "getReportListing";
export const DELETE_REPORT = "deleteReport";
export const ACTION_ON_FEED = "action-on-feed";
export const POST_PROFILE_LISTING = "getPostProfileListing";
export const POST_PROFILE_BY_CATEGORY = "getProfileByCategory";
export const POST_PROFILE_ALL_CATEGORY = "filterForAllCategory";
export const Dashboard = "getProfilesCount";
export const CONTACT_US_LISTING = "contactUsListing";
export const ME_URL = "api/me";

export function login(email, password) {
  return postApi(LOGIN_URL, { email, password });
}
export function dashboard() {
  return getApi(Dashboard);
}

export function forgotpassword(email) {
  return postApi(FORGOT, { email: email });
}

export function changePassword(currentPassword) {
  return postApi(CHANGE_PASSWORD, currentPassword);
}

export function updateAdminProfile(values) {
  return postApi(UPDATE_ADMINPROFILE, values);
}
export function deactiveProfileListing() {
  return getApi("deactivateProfileListing");
}
export function userListing(page) {
  return getApi(`${UserList}?page=${page}`);
}
export function filterListingOnUsers(filters) {
  return getApi(`${filterListing}?filterOption=${filters}`);
}
export function postProfileListing(page) {
  return getApi(`${POST_PROFILE_LISTING}?pageNumber=${page}`);
}
export function categoryListing(page) {
  return getApi(`${CATEGORY_LIST}?page=${page}`);
}
export function contactusListing(page) {
  return getApi(`${CONTACT_US_LISTING}?pageNumber=${page}`);
}

export function profileLisitingByCat(id) {
  return getApi(`${POST_PROFILE_BY_CATEGORY}?categoryId=${id}`);
}
export function profileLisitingAllCat() {
  return getApi(POST_PROFILE_ALL_CATEGORY);
}

export function userDelete(id) {
  return deleteApi(`${USER_DELETE}?profileId=${id}`);
}

export function blockUnblockUserbyAdmin(values) {
  return postApi(BLOCK_UNBLOCK_BY_ADMIN, values);
}

export function activeDeactivePostProfile(values) {
  return postApi(ACTIVE_DEACTIVE_POSTPROFILE, values);
}
export function userDetails(userID) {
  return getApi(`${UserDetails}?userId=${userID}`);
}

export function postProfileDetails(userID) {
  return getApi(`${VIEW_POST_PROFILE}?profileId=${userID}`);
}

export function searchUser(value) {
  return postApi(`${SEARCH_USER}?search=${value}`);
}
export function searchprofile(value) {
  return postApi(`${SEARCH_PROFILE}?search=${value}`);
}
export function searchDeactiveprofile(value) {
  return getApi(`${SEARCH_DEACTIVE_PROFILE}?search=${value}`);
}

export function blockUnblockUser(values) {
  return postApi(BLOCK_UNBLOCK_BY_ADMIN, values);
}

export function updateContactStatus(values) {
  return postApi(UPDATE_CONTACT_STATUS, values);
}
export function ReportListing() {
  return getApi(REPORT_LISTING);
}
export function deleteReport(id) {
  return deleteApi(`${DELETE_REPORT}?reportId=${id} `);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
