/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    id: 1,
    carId: 1,
    text: "enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin",
    _userId: 2,
    _createdDate: "02/20/2011",
    _updatedDate: "06/25/2013",
    type: 0,
    _isEditMode: false,
    dueDate: "11/18/2018"
  },
  {
    id: 2,
    carId: 1,
    text: "in",
    _userId: 1,
    _createdDate: "02/05/2014",
    _updatedDate: "09/25/2017",
    type: 0,
    _isEditMode: false,
    dueDate: "10/26/2019"
  },
  {
    id: 3,
    carId: 1,
    text: "quis tortor id nulla ultrices aliquet maecenas leo",
    _userId: 2,
    _createdDate: "04/10/2017",
    _updatedDate: "06/12/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "05/10/2020"
  },
  {
    id: 4,
    carId: 2,
    text: "curabitur gravida nisi at nibh in hac habitasse",
    _userId: 1,
    _createdDate: "03/02/2015",
    _updatedDate: "03/30/2014",
    type: 1,
    _isEditMode: false,
    dueDate: "12/13/2018"
  },
  {
    id: 5,
    carId: 2,
    text:
      "orci luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis",
    _userId: 2,
    _createdDate: "02/14/2015",
    _updatedDate: "07/11/2013",
    type: 0,
    _isEditMode: false,
    dueDate: "10/24/2019"
  },
  {
    id: 6,
    carId: 2,
    text: "integer ac",
    _userId: 1,
    _createdDate: "05/28/2015",
    _updatedDate: "07/29/2016",
    type: 0,
    _isEditMode: false,
    dueDate: "01/22/2019"
  },
  {
    id: 7,
    carId: 3,
    text:
      "lectus aliquam sit amet diam in magna bibendum imperdiet nullam orci pede",
    _userId: 2,
    _createdDate: "03/08/2013",
    _updatedDate: "09/24/2016",
    type: 1,
    _isEditMode: false,
    dueDate: "03/31/2020"
  },
  {
    id: 8,
    carId: 3,
    text: "morbi odio odio elementum eu interdum eu tincidunt in leo",
    _userId: 2,
    _createdDate: "07/09/2010",
    _updatedDate: "01/25/2015",
    type: 1,
    _isEditMode: false,
    dueDate: "02/20/2019"
  },
  {
    id: 9,
    carId: 3,
    text: "orci",
    _userId: 1,
    _createdDate: "02/02/2018",
    _updatedDate: "08/04/2015",
    type: 0,
    _isEditMode: false,
    dueDate: "04/03/2020"
  },
  {
    id: 10,
    carId: 4,
    text:
      "vel pede morbi porttitor lorem id ligula suspendisse ornare consequat lectus in est risus auctor sed tristique in tempus sit",
    _userId: 1,
    _createdDate: "09/09/2013",
    _updatedDate: "03/06/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "10/28/2018"
  },
  {
    id: 11,
    carId: 4,
    text: "ligula sit amet eleifend pede libero quis orci",
    _userId: 2,
    _createdDate: "08/17/2010",
    _updatedDate: "03/05/2016",
    type: 2,
    _isEditMode: false,
    dueDate: "06/22/2019"
  },
  {
    id: 12,
    carId: 4,
    text: "eget eleifend luctus ultricies eu nibh quisque id justo sit amet",
    _userId: 2,
    _createdDate: "09/17/2014",
    _updatedDate: "04/18/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "01/01/2020"
  },
  {
    id: 13,
    carId: 5,
    text: "",
    _userId: 1,
    _createdDate: "03/25/2013",
    _updatedDate: "11/13/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "05/25/2019"
  },
  {
    id: 14,
    carId: 5,
    text: "justo lacinia eget tincidunt eget tempus vel pede morbi porttitor",
    _userId: 2,
    _createdDate: "03/22/2015",
    _updatedDate: "04/22/2015",
    type: 1,
    _isEditMode: false,
    dueDate: "02/07/2020"
  },
  {
    id: 15,
    carId: 5,
    text: "quam pede",
    _userId: 1,
    _createdDate: "03/18/2018",
    _updatedDate: "06/01/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "02/09/2020"
  },
  {
    id: 16,
    carId: 6,
    text:
      "bibendum morbi non quam nec dui luctus rutrum nulla tellus in sagittis dui vel nisl duis ac nibh fusce",
    _userId: 2,
    _createdDate: "01/09/2013",
    _updatedDate: "02/10/2017",
    type: 1,
    _isEditMode: false,
    dueDate: "09/21/2019"
  },
  {
    id: 17,
    carId: 6,
    text: "id consequat in consequat ut nulla sed accumsan felis ut at dolor",
    _userId: 2,
    _createdDate: "07/27/2012",
    _updatedDate: "04/13/2015",
    type: 1,
    _isEditMode: false,
    dueDate: "08/01/2019"
  },
  {
    id: 18,
    carId: 6,
    text:
      "eu magna vulputate luctus cum sociis natoque penatibus et magnis dis parturient",
    _userId: 1,
    _createdDate: "08/12/2011",
    _updatedDate: "04/08/2018",
    type: 0,
    _isEditMode: false,
    dueDate: "03/28/2020"
  },
  {
    id: 19,
    carId: 7,
    text:
      "nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel nulla eget",
    _userId: 1,
    _createdDate: "03/03/2013",
    _updatedDate: "09/18/2017",
    type: 0,
    _isEditMode: false,
    dueDate: "08/08/2019"
  },
  {
    id: 20,
    carId: 7,
    text: "",
    _userId: 1,
    _createdDate: "05/06/2015",
    _updatedDate: "02/20/2016",
    type: 2,
    _isEditMode: false,
    dueDate: "02/11/2019"
  },
  {
    id: 21,
    carId: 7,
    text:
      "sed vestibulum sit amet cursus id turpis integer aliquet massa id lobortis convallis",
    _userId: 2,
    _createdDate: "08/05/2014",
    _updatedDate: "03/13/2018",
    type: 0,
    _isEditMode: false,
    dueDate: "02/03/2020"
  },
  {
    id: 22,
    carId: 8,
    text: "dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo",
    _userId: 2,
    _createdDate: "04/25/2011",
    _updatedDate: "03/09/2013",
    type: 0,
    _isEditMode: false,
    dueDate: "12/06/2018"
  },
  {
    id: 23,
    carId: 8,
    text:
      "mauris lacinia sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat morbi a ipsum integer a nibh",
    _userId: 1,
    _createdDate: "07/03/2014",
    _updatedDate: "08/23/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "05/18/2019"
  },
  {
    id: 24,
    carId: 8,
    text:
      "ultrices posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat tortor sollicitudin",
    _userId: 2,
    _createdDate: "05/21/2013",
    _updatedDate: "10/01/2016",
    type: 0,
    _isEditMode: false,
    dueDate: "04/18/2019"
  },
  {
    id: 25,
    carId: 9,
    text: "orci luctus et ultrices",
    _userId: 2,
    _createdDate: "05/29/2014",
    _updatedDate: "02/03/2016",
    type: 1,
    _isEditMode: false,
    dueDate: "06/23/2019"
  },
  {
    id: 26,
    carId: 9,
    text: "donec diam neque vestibulum eget vulputate ut",
    _userId: 1,
    _createdDate: "06/06/2012",
    _updatedDate: "02/01/2018",
    type: 2,
    _isEditMode: false,
    dueDate: "12/29/2018"
  },
  {
    id: 27,
    carId: 9,
    text: "sapien sapien non mi integer ac neque duis bibendum morbi non quam",
    _userId: 1,
    _createdDate: "05/12/2015",
    _updatedDate: "11/11/2011",
    type: 2,
    _isEditMode: false,
    dueDate: "04/26/2020"
  },
  {
    id: 28,
    carId: 10,
    text: "",
    _userId: 1,
    _createdDate: "03/17/2013",
    _updatedDate: "08/13/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "05/12/2019"
  },
  {
    id: 29,
    carId: 10,
    text:
      "aliquam lacus morbi quis tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed",
    _userId: 1,
    _createdDate: "07/03/2016",
    _updatedDate: "02/06/2011",
    type: 2,
    _isEditMode: false,
    dueDate: "02/24/2019"
  },
  {
    id: 30,
    carId: 10,
    text: "donec quis orci eget orci vehicula condimentum",
    _userId: 2,
    _createdDate: "01/27/2014",
    _updatedDate: "12/09/2016",
    type: 1,
    _isEditMode: false,
    dueDate: "02/27/2020"
  },
  {
    id: 31,
    carId: 11,
    text: "etiam faucibus cursus urna ut tellus nulla ut erat",
    _userId: 1,
    _createdDate: "07/08/2014",
    _updatedDate: "06/07/2014",
    type: 1,
    _isEditMode: false,
    dueDate: "05/26/2020"
  },
  {
    id: 32,
    carId: 11,
    text:
      "hac habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla ut erat id mauris vulputate elementum nullam varius",
    _userId: 1,
    _createdDate: "05/03/2016",
    _updatedDate: "07/20/2012",
    type: 1,
    _isEditMode: false,
    dueDate: "08/11/2019"
  },
  {
    id: 33,
    carId: 11,
    text: "vel enim sit amet",
    _userId: 1,
    _createdDate: "06/02/2017",
    _updatedDate: "05/03/2012",
    type: 0,
    _isEditMode: false,
    dueDate: "12/18/2019"
  },
  {
    id: 34,
    carId: 12,
    text:
      "est congue elementum in hac habitasse platea dictumst morbi vestibulum",
    _userId: 2,
    _createdDate: "03/28/2017",
    _updatedDate: "07/23/2010",
    type: 1,
    _isEditMode: false,
    dueDate: "02/16/2019"
  },
  {
    id: 35,
    carId: 12,
    text: "tempus vel pede morbi",
    _userId: 1,
    _createdDate: "10/29/2013",
    _updatedDate: "01/22/2016",
    type: 0,
    _isEditMode: false,
    dueDate: "12/30/2018"
  },
  {
    id: 36,
    carId: 12,
    text:
      "augue vel accumsan tellus nisi eu orci mauris lacinia sapien quis libero nullam sit amet turpis",
    _userId: 2,
    _createdDate: "03/31/2011",
    _updatedDate: "08/20/2012",
    type: 0,
    _isEditMode: false,
    dueDate: "06/29/2019"
  },
  {
    id: 37,
    carId: 13,
    text: "faucibus",
    _userId: 2,
    _createdDate: "12/24/2016",
    _updatedDate: "03/01/2017",
    type: 2,
    _isEditMode: false,
    dueDate: "04/09/2020"
  },
  {
    id: 38,
    carId: 13,
    text:
      "vulputate luctus cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus",
    _userId: 1,
    _createdDate: "08/02/2015",
    _updatedDate: "10/24/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "03/03/2020"
  },
  {
    id: 39,
    carId: 13,
    text:
      "nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac",
    _userId: 1,
    _createdDate: "03/27/2011",
    _updatedDate: "11/25/2017",
    type: 2,
    _isEditMode: false,
    dueDate: "12/22/2019"
  },
  {
    id: 40,
    carId: 14,
    text:
      "vel accumsan tellus nisi eu orci mauris lacinia sapien quis libero nullam sit amet turpis elementum",
    _userId: 1,
    _createdDate: "02/03/2017",
    _updatedDate: "03/15/2014",
    type: 0,
    _isEditMode: false,
    dueDate: "02/06/2020"
  },
  {
    id: 41,
    carId: 14,
    text: "nec dui luctus rutrum nulla tellus in sagittis dui vel",
    _userId: 2,
    _createdDate: "08/12/2013",
    _updatedDate: "03/26/2013",
    type: 0,
    _isEditMode: false,
    dueDate: "09/22/2019"
  },
  {
    id: 42,
    carId: 14,
    text: "integer aliquet massa id lobortis convallis",
    _userId: 1,
    _createdDate: "09/09/2013",
    _updatedDate: "03/06/2018",
    type: 1,
    _isEditMode: false,
    dueDate: "01/19/2019"
  },
  {
    id: 43,
    carId: 15,
    text: "proin leo odio porttitor id consequat in consequat",
    _userId: 2,
    _createdDate: "04/25/2015",
    _updatedDate: "09/29/2015",
    type: 0,
    _isEditMode: false,
    dueDate: "11/02/2019"
  },
  {
    id: 44,
    carId: 15,
    text:
      "mauris enim leo rhoncus sed vestibulum sit amet cursus id turpis integer aliquet massa id lobortis convallis tortor",
    _userId: 1,
    _createdDate: "11/28/2013",
    _updatedDate: "10/12/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "02/28/2019"
  },
  {
    id: 45,
    carId: 15,
    text:
      "felis donec semper sapien a libero nam dui proin leo odio porttitor id consequat in consequat ut",
    _userId: 1,
    _createdDate: "12/24/2016",
    _updatedDate: "03/18/2016",
    type: 1,
    _isEditMode: false,
    dueDate: "11/13/2019"
  },
  {
    id: 46,
    carId: 16,
    text:
      "id lobortis convallis tortor risus dapibus augue vel accumsan tellus nisi eu orci mauris lacinia sapien quis libero",
    _userId: 2,
    _createdDate: "09/16/2015",
    _updatedDate: "09/20/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "11/28/2019"
  },
  {
    id: 47,
    carId: 16,
    text: "semper rutrum nulla nunc purus phasellus",
    _userId: 1,
    _createdDate: "04/09/2017",
    _updatedDate: "08/19/2012",
    type: 0,
    _isEditMode: false,
    dueDate: "11/05/2019"
  },
  {
    id: 48,
    carId: 16,
    text: "curabitur at ipsum ac tellus semper",
    _userId: 2,
    _createdDate: "10/09/2010",
    _updatedDate: "11/20/2014",
    type: 2,
    _isEditMode: false,
    dueDate: "11/27/2018"
  },
  {
    id: 49,
    carId: 17,
    text: "tellus nisi eu orci mauris lacinia sapien",
    _userId: 1,
    _createdDate: "11/18/2012",
    _updatedDate: "10/24/2017",
    type: 1,
    _isEditMode: false,
    dueDate: "04/10/2019"
  },
  {
    id: 50,
    carId: 17,
    text: "quisque id justo sit amet sapien dignissim vestibulum vestibulum",
    _userId: 1,
    _createdDate: "09/21/2015",
    _updatedDate: "11/27/2016",
    type: 2,
    _isEditMode: false,
    dueDate: "12/17/2018"
  },
  {
    id: 51,
    carId: 17,
    text:
      "diam id ornare imperdiet sapien urna pretium nisl ut volutpat sapien arcu sed",
    _userId: 1,
    _createdDate: "08/13/2012",
    _updatedDate: "06/21/2016",
    type: 1,
    _isEditMode: false,
    dueDate: "06/11/2019"
  },
  {
    id: 52,
    carId: 18,
    text: "eget nunc donec",
    _userId: 1,
    _createdDate: "03/30/2012",
    _updatedDate: "08/05/2012",
    type: 1,
    _isEditMode: false,
    dueDate: "11/15/2018"
  },
  {
    id: 53,
    carId: 18,
    text: "nulla ac enim in tempor",
    _userId: 1,
    _createdDate: "04/30/2012",
    _updatedDate: "11/12/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "01/16/2020"
  },
  {
    id: 54,
    carId: 18,
    text: "in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id",
    _userId: 2,
    _createdDate: "08/29/2012",
    _updatedDate: "12/10/2012",
    type: 0,
    _isEditMode: false,
    dueDate: "09/25/2019"
  },
  {
    id: 55,
    carId: 19,
    text: "justo in",
    _userId: 2,
    _createdDate: "11/27/2017",
    _updatedDate: "10/03/2010",
    type: 0,
    _isEditMode: false,
    dueDate: "10/04/2019"
  },
  {
    id: 56,
    carId: 19,
    text: "duis",
    _userId: 2,
    _createdDate: "07/09/2016",
    _updatedDate: "02/13/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "05/07/2020"
  },
  {
    id: 57,
    carId: 19,
    text:
      "luctus tincidunt nulla mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh in hac habitasse platea",
    _userId: 1,
    _createdDate: "01/10/2014",
    _updatedDate: "01/07/2014",
    type: 0,
    _isEditMode: false,
    dueDate: "04/01/2020"
  },
  {
    id: 58,
    carId: 20,
    text: "non sodales sed tincidunt",
    _userId: 1,
    _createdDate: "02/15/2016",
    _updatedDate: "12/12/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "08/26/2019"
  },
  {
    id: 59,
    carId: 20,
    text:
      "dictumst aliquam augue quam sollicitudin vitae consectetuer eget rutrum at lorem integer tincidunt ante vel ipsum",
    _userId: 2,
    _createdDate: "01/08/2018",
    _updatedDate: "06/20/2011",
    type: 2,
    _isEditMode: false,
    dueDate: "10/27/2019"
  },
  {
    id: 60,
    carId: 20,
    text: "adipiscing molestie hendrerit at vulputate",
    _userId: 1,
    _createdDate: "01/06/2013",
    _updatedDate: "09/08/2017",
    type: 2,
    _isEditMode: false,
    dueDate: "05/03/2020"
  },
  {
    id: 61,
    carId: 21,
    text: "sed tristique in tempus sit amet sem fusce consequat nulla nisl",
    _userId: 1,
    _createdDate: "01/05/2012",
    _updatedDate: "06/05/2014",
    type: 1,
    _isEditMode: false,
    dueDate: "04/06/2019"
  },
  {
    id: 62,
    carId: 21,
    text: "sem praesent id massa id nisl venenatis lacinia aenean sit",
    _userId: 2,
    _createdDate: "12/06/2014",
    _updatedDate: "12/23/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "02/05/2019"
  },
  {
    id: 63,
    carId: 21,
    text: "curabitur at ipsum ac tellus semper interdum",
    _userId: 2,
    _createdDate: "11/23/2014",
    _updatedDate: "04/10/2013",
    type: 0,
    _isEditMode: false,
    dueDate: "04/05/2020"
  },
  {
    id: 64,
    carId: 22,
    text: "donec ut mauris eget massa tempor convallis",
    _userId: 2,
    _createdDate: "10/16/2011",
    _updatedDate: "11/30/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "11/23/2019"
  },
  {
    id: 65,
    carId: 22,
    text: "in faucibus orci luctus et ultrices posuere",
    _userId: 1,
    _createdDate: "11/12/2011",
    _updatedDate: "12/16/2016",
    type: 2,
    _isEditMode: false,
    dueDate: "04/23/2019"
  },
  {
    id: 66,
    carId: 22,
    text: "ligula",
    _userId: 2,
    _createdDate: "12/30/2014",
    _updatedDate: "12/12/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "03/28/2019"
  },
  {
    id: 67,
    carId: 23,
    text:
      "congue etiam justo etiam pretium iaculis justo in hac habitasse platea dictumst etiam faucibus cursus",
    _userId: 1,
    _createdDate: "05/09/2014",
    _updatedDate: "05/28/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "11/25/2018"
  },
  {
    id: 68,
    carId: 23,
    text: "mollis molestie lorem quisque ut erat curabitur gravida nisi",
    _userId: 2,
    _createdDate: "12/07/2011",
    _updatedDate: "08/07/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "06/22/2019"
  },
  {
    id: 69,
    carId: 23,
    text:
      "maecenas tincidunt lacus at velit vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat erat quisque",
    _userId: 1,
    _createdDate: "11/15/2012",
    _updatedDate: "09/02/2015",
    type: 1,
    _isEditMode: false,
    dueDate: "10/30/2018"
  },
  {
    id: 70,
    carId: 24,
    text: "libero convallis",
    _userId: 1,
    _createdDate: "12/04/2010",
    _updatedDate: "09/25/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "11/25/2018"
  },
  {
    id: 71,
    carId: 24,
    text: "vitae nisi nam ultrices libero",
    _userId: 1,
    _createdDate: "02/24/2015",
    _updatedDate: "03/10/2011",
    type: 2,
    _isEditMode: false,
    dueDate: "04/17/2020"
  },
  {
    id: 72,
    carId: 24,
    text: "augue a suscipit nulla elit ac nulla sed vel enim sit amet nunc",
    _userId: 1,
    _createdDate: "02/21/2012",
    _updatedDate: "12/09/2011",
    type: 2,
    _isEditMode: false,
    dueDate: "07/22/2019"
  },
  {
    id: 73,
    carId: 25,
    text: "id turpis integer aliquet massa id lobortis convallis tortor",
    _userId: 1,
    _createdDate: "01/20/2014",
    _updatedDate: "09/03/2014",
    type: 2,
    _isEditMode: false,
    dueDate: "01/10/2020"
  },
  {
    id: 74,
    carId: 25,
    text: "felis fusce posuere felis sed lacus morbi sem mauris laoreet",
    _userId: 1,
    _createdDate: "10/24/2014",
    _updatedDate: "02/19/2018",
    type: 1,
    _isEditMode: false,
    dueDate: "12/19/2019"
  },
  {
    id: 75,
    carId: 25,
    text:
      "neque libero convallis eget eleifend luctus ultricies eu nibh quisque id justo sit amet sapien dignissim",
    _userId: 2,
    _createdDate: "02/19/2016",
    _updatedDate: "06/26/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "02/23/2020"
  },
  {
    id: 76,
    carId: 26,
    text:
      "nulla nunc purus phasellus in felis donec semper sapien a libero nam",
    _userId: 1,
    _createdDate: "07/29/2011",
    _updatedDate: "12/09/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "02/07/2020"
  },
  {
    id: 77,
    carId: 26,
    text: "elit sodales scelerisque",
    _userId: 2,
    _createdDate: "10/02/2012",
    _updatedDate: "05/05/2012",
    type: 0,
    _isEditMode: false,
    dueDate: "10/05/2019"
  },
  {
    id: 78,
    carId: 26,
    text:
      "sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar sed nisl nunc rhoncus dui vel",
    _userId: 1,
    _createdDate: "01/27/2015",
    _updatedDate: "07/14/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "01/19/2019"
  },
  {
    id: 79,
    carId: 27,
    text: "augue vestibulum",
    _userId: 1,
    _createdDate: "09/19/2011",
    _updatedDate: "09/11/2014",
    type: 0,
    _isEditMode: false,
    dueDate: "03/08/2020"
  },
  {
    id: 80,
    carId: 27,
    text: "turpis nec euismod scelerisque",
    _userId: 2,
    _createdDate: "01/17/2014",
    _updatedDate: "03/04/2018",
    type: 1,
    _isEditMode: false,
    dueDate: "11/08/2019"
  },
  {
    id: 81,
    carId: 27,
    text:
      "quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus",
    _userId: 1,
    _createdDate: "08/16/2015",
    _updatedDate: "02/21/2011",
    type: 1,
    _isEditMode: false,
    dueDate: "02/15/2019"
  },
  {
    id: 82,
    carId: 28,
    text:
      "ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend",
    _userId: 1,
    _createdDate: "03/07/2014",
    _updatedDate: "08/21/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "05/14/2019"
  },
  {
    id: 83,
    carId: 28,
    text:
      "curabitur gravida nisi at nibh in hac habitasse platea dictumst aliquam augue",
    _userId: 2,
    _createdDate: "09/20/2017",
    _updatedDate: "08/21/2014",
    type: 0,
    _isEditMode: false,
    dueDate: "07/07/2019"
  },
  {
    id: 84,
    carId: 28,
    text:
      "dolor quis odio consequat varius integer ac leo pellentesque ultrices mattis odio donec vitae nisi",
    _userId: 2,
    _createdDate: "04/08/2017",
    _updatedDate: "08/17/2016",
    type: 1,
    _isEditMode: false,
    dueDate: "03/19/2019"
  },
  {
    id: 85,
    carId: 29,
    text:
      "interdum in ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae duis faucibus",
    _userId: 2,
    _createdDate: "04/30/2013",
    _updatedDate: "08/22/2013",
    type: 2,
    _isEditMode: false,
    dueDate: "03/23/2020"
  },
  {
    id: 86,
    carId: 29,
    text:
      "faucibus orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec odio justo sollicitudin ut suscipit a",
    _userId: 2,
    _createdDate: "04/12/2014",
    _updatedDate: "03/06/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "02/17/2019"
  },
  {
    id: 87,
    carId: 29,
    text:
      "faucibus orci luctus et ultrices posuere cubilia curae donec pharetra magna vestibulum",
    _userId: 2,
    _createdDate: "11/21/2016",
    _updatedDate: "08/21/2010",
    type: 1,
    _isEditMode: false,
    dueDate: "11/06/2019"
  },
  {
    id: 88,
    carId: 30,
    text: "nibh in hac habitasse platea dictumst",
    _userId: 1,
    _createdDate: "10/30/2011",
    _updatedDate: "03/07/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "12/02/2019"
  },
  {
    id: 89,
    carId: 30,
    text: "fusce lacus purus",
    _userId: 2,
    _createdDate: "09/02/2014",
    _updatedDate: "05/22/2015",
    type: 2,
    _isEditMode: false,
    dueDate: "10/13/2019"
  },
  {
    id: 90,
    carId: 30,
    text: "ultrices enim lorem ipsum dolor",
    _userId: 1,
    _createdDate: "12/21/2012",
    _updatedDate: "02/28/2018",
    type: 1,
    _isEditMode: false,
    dueDate: "12/08/2019"
  },
  {
    id: 91,
    carId: 31,
    text: "suspendisse accumsan tortor quis turpis",
    _userId: 1,
    _createdDate: "01/22/2014",
    _updatedDate: "01/26/2015",
    type: 2,
    _isEditMode: false,
    dueDate: "03/05/2019"
  },
  {
    id: 92,
    carId: 31,
    text:
      "sed interdum venenatis turpis enim blandit mi in porttitor pede justo eu massa",
    _userId: 1,
    _createdDate: "12/20/2013",
    _updatedDate: "08/13/2016",
    type: 0,
    _isEditMode: false,
    dueDate: "09/03/2019"
  },
  {
    id: 93,
    carId: 31,
    text:
      "quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis",
    _userId: 1,
    _createdDate: "07/08/2016",
    _updatedDate: "02/26/2013",
    type: 0,
    _isEditMode: false,
    dueDate: "01/22/2019"
  },
  {
    id: 94,
    carId: 32,
    text: "vitae nisi nam ultrices",
    _userId: 1,
    _createdDate: "02/21/2015",
    _updatedDate: "03/04/2017",
    type: 1,
    _isEditMode: false,
    dueDate: "01/05/2020"
  },
  {
    id: 95,
    carId: 32,
    text: "in tempus sit amet sem fusce consequat",
    _userId: 2,
    _createdDate: "09/15/2014",
    _updatedDate: "05/21/2017",
    type: 1,
    _isEditMode: false,
    dueDate: "03/31/2019"
  },
  {
    id: 96,
    carId: 32,
    text:
      "eros elementum pellentesque quisque porta volutpat erat quisque erat eros viverra eget congue eget semper",
    _userId: 2,
    _createdDate: "06/04/2014",
    _updatedDate: "08/12/2010",
    type: 0,
    _isEditMode: false,
    dueDate: "10/10/2019"
  },
  {
    id: 97,
    carId: 33,
    text: "eu pede",
    _userId: 2,
    _createdDate: "03/21/2011",
    _updatedDate: "07/18/2012",
    type: 2,
    _isEditMode: false,
    dueDate: "03/29/2020"
  },
  {
    id: 98,
    carId: 33,
    text: "tempus vivamus in felis eu sapien cursus vestibulum proin",
    _userId: 2,
    _createdDate: "09/17/2017",
    _updatedDate: "11/29/2013",
    type: 1,
    _isEditMode: false,
    dueDate: "04/01/2019"
  },
  {
    id: 99,
    carId: 33,
    text:
      "velit eu est congue elementum in hac habitasse platea dictumst morbi vestibulum velit id pretium iaculis diam erat fermentum",
    _userId: 1,
    _createdDate: "03/27/2013",
    _updatedDate: "09/26/2014",
    type: 1,
    _isEditMode: false,
    dueDate: "09/14/2019"
  },
  {
    id: 100,
    carId: 34,
    text: "luctus et ultrices posuere cubilia curae nulla dapibus dolor vel",
    _userId: 2,
    _createdDate: "03/07/2017",
    _updatedDate: "08/14/2012",
    type: 0,
    _isEditMode: false,
    dueDate: "08/11/2019"
  }
];
