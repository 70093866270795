import React from "react";
import { AdvanceTablesWidget10 } from "../../../_metronic/_partials/widgets/advance-tables/AdvanceTablesWidget10";

export default function UserMedia({ data }) {
  return (
    <>
      <AdvanceTablesWidget10 data= {data} />
    </>
  );
}
