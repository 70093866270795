/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  filterListingOnUsers,
  searchUser,
  blockUnblockUserbyAdmin,
} from "../../../../app/modules/Auth/_redux/authCrud";
import DeleteBlock from "../../../../app/modules/Modals/DeleteBlock";
import ViewUserModal from "../../../../app/modules/UserProfile/components/ViewUserModal";
import {
  ListingProfilePictures,
  NoData,
  // PageChanger,
  // PagedDiv,
} from "../../../../GobalStyled";

export function AdvanceTablesWidget2({ className }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [parameter, setParameter] = useState({});
  const [filter, setFilter] = useState("all");

  const [toggle, setToggle] = useState("All");
  const [data, setData] = useState([]);
  // const history = useHistory();
  const baseUrl = "https://d17am3tdlggecz.cloudfront.net/";

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getSearchWord = async (word) => {
    let s = word;
    setSearchVal(s);
    // console.log({ searchKeyword: word }, " ===== data??????");
    if (s) {
      await searchUser(s)
        .then(({ data }) => {
          setToggle("ALL");
          // console.log(data, "data--------------");
          setUserData(data);
        })
        .catch(() => {
          disableLoading();
        });
    }
    if (!s) {
      setUserData([]);
      setToggle("ALL");
      setFilter("all");
      getUserListing();
    }
  };

  const getUserListing = () => {
    enableLoading();
    filterListingOnUsers(filter)
      .then(({ data }) => {
        setUserData(data);
        // console.log(data);
        disableLoading();
        // dispatch(loginAdmin(data))
      })
      .catch(() => {
        disableLoading();
      });
  };

  const block_unblock = (payload, action) => {
    setOpenDelete(true);

    let para = {
      name: action,
      body: "this user",
      req: {
        userId: payload._id,
        userStatus: payload?.user_status === true ? false : true,
      },
    };
    setParameter(para);
  };

  const ActiveDeactive = async () => {
    setModalLoading(true);
    let res = await blockUnblockUserbyAdmin(parameter.req);

    if (res.status === 200) {
      setModalLoading(false);
      setOpenDelete(false);
      toast.info(res.message);
      if (searchVal !== "") {
        console.log(searchVal, "ssss");
        getSearchWord(searchVal);
      } else {
        getUserListing();
      }
    } else {
      setModalLoading(false);
      setOpenDelete(false);
      toast.info(res.message);
    }
  };

  useEffect(() => {
    getUserListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }
  const handleDropdown = (value) => {
    setPage(1);
    setFilter(value);
    setToggle(value.toUpperCase());
  };
  const getToggleClass = () => {
    if (toggle === "BLOCKED") {
      return "danger";
    } else {
      if (toggle === "UNBLOCKED") {
        return "info";
      } else {
        return "success";
      }
    }
  };

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {openDelete && (
          <DeleteBlock
            open={openDelete}
            close={() => setOpenDelete(false)}
            confirmSumbit={ActiveDeactive}
            parameter={parameter}
            loading={modalLoading}
          />
        )}

        <div className="card-header border-0 pt-4">
          <Dropdown>
            <Dropdown.Toggle variant={getToggleClass()} id="dropdown-basic">
              {toggle}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ zIndex: "2" }}>
              {toggle !== "ALL" ? (
                <Dropdown.Item onClick={() => handleDropdown("all")}>
                  All
                </Dropdown.Item>
              ) : null}
              {toggle !== "BLOCKED" ? (
                <Dropdown.Item onClick={() => handleDropdown("blocked")}>
                  Blocked
                </Dropdown.Item>
              ) : null}
              {toggle !== "UNBLOCKED" ? (
                <Dropdown.Item onClick={() => handleDropdown("unblocked")}>
                  Unblocked
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
          <input
            name="search"
            type="search"
            placeholder="Search"
            onChange={(e) => getSearchWord(e.target.value)}
            className="form-control w-25  ml-auto"
          />
        </div>
        {userData?.length > 0 ? (
          userData.map((ele) => (
            <div className="card-body pt-3 pb-0">
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th className="p-0" style={{ width: "60px" }} />
                      <th className="p-0" style={{ minWidth: "250px" }} />
                      <th className="p-0" style={{ minWidth: "100px" }} />
                      {/* <th className="p-0" style={{ minWidth: "125px" }} /> */}
                      {/* <th className="p-0" style={{ minWidth: "110px" }} /> */}
                      <th className="p-0" style={{ minWidth: "150px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pl-0 py-4">
                        <div className="symbol symbol-50 symbol-light mr-1">
                          <span className="symbol-label">
                            {ele?.profile_picture ? (
                              <ListingProfilePictures
                                src={baseUrl + ele?.profile_picture}
                                alt="Profile"
                              />
                            ) : (
                              <div className="symbol  text-center">
                                <span className="bg-secondary symbol-label text-success text-center font-weight-bold font-size-h5">
                                  {ele?.first_name[0]}
                                  {ele?.last_name[0]}
                                </span>
                              </div>
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="pl-0">
                        <span
                          // onClick={() =>
                          //   history.push("/user-details", { id: ele._id })
                          //   history.push("/user-details", { id: ele._id })
                          // }
                          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                        >
                          {ele.first_name + " " + ele.last_name}
                        </span>
                        <div>
                          <span className="font-weight-bolder">Email: </span>
                          <a
                            className="text-muted font-weight-bold text-hover-primary"
                            href="#"
                          >
                            {ele.email}
                          </a>
                        </div>
                      </td>

                      <td className="text-right">
                        {/* <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {ele.mobile_number}
                      </span>

                      <span className="text-muted font-weight-bold">
                        <span className="label label-lg label-light-primary label-inline my-1 cursor-pointer">
                          {ele.user_name}
                        </span>
                      </span> */}
                      </td>

                      <td className="text-right pr-0">
                        {ele?.user_status === true ? (
                          <button
                            className="btn btn-light-danger  btn-sm font-weight-bolder font-size-sm mx-1"
                            onClick={() => block_unblock(ele, "Block")}
                          >
                            Block
                          </button>
                        ) : (
                          <button
                            className="btn btn-light-info  btn-sm font-weight-bolder font-size-sm mx-1"
                            onClick={() => block_unblock(ele, "Unblock")}
                          >
                            Unblock
                          </button>
                        )}
                        <button
                          className="btn btn-light-success btn-sm font-weight-bolder font-size-sm mx-1"
                          onClick={() => {
                            setData(ele);
                            setShow(true);
                          }}
                        >
                          View
                        </button>

                        {/* Delete button  */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))
        ) : (
          <NoData>DATA NOT FOUND</NoData>
        )}
        {/* <PageChanger>
            <PagedDiv>
              <ul>
                {page > 1 && <li onClick={() => setPage(page - 1)}>Previous</li>}
                {userData?.length <= 20 && page > 1 && (
                  <li onClick={() => setPage(page - 1)}>
                    {userData?.length <= 2 ? page - 1 : ""}
                  </li>
                )}
                <li className="active">{page}</li>
                {userData?.length >= 20 && (
                  <li onClick={() => setPage(page + 1)}>
                    {userData?.length >= 2 ? page + 1 : ""}
                  </li>
                )}
                {userData?.length >= 20 && (
                  <li onClick={() => setPage(page + 1)}>
                    {userData?.length >= 2 ? "Next" : ""}
                  </li>
                )}
              </ul>
            </PagedDiv>
          </PageChanger> */}
        {show && (
          <ViewUserModal open={show} data={data} close={() => setShow(false)} />
        )}
      </div>
    </>
  );
}
