import React, { useState } from "react";
import { useHistory } from "react-router";
// import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  ActionButtonForUser,
  PageInnerContainer,
  UserFollowingDetails,
  UserInfoBox,
  UserProfileInfo,
  // UserProfileInfoInner,
} from "../../../GobalStyled";
import { activeDeactivePostProfile, userDelete } from "../Auth/_redux/authCrud";
import DeleteBlock from "../Modals/DeleteBlock";
import ReportInfo from "./components/ReportInfo";

export default function ViewReport(props) {
  const [activeTab, setActiveTab] = useState("info");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(props.location.state.data);
  const [openModal, setOpenModal] = useState(false);
  const [parameter, setParameter] = useState({});
  const [modalLoder, setModalLoder] = useState(false);

  const baseurl = "https://d17am3tdlggecz.cloudfront.net/";

  const history = useHistory();

  const deleteAction = async () => {
    setModalLoder(true);
    if (parameter.name === "Delete") {
      let res = await userDelete(parameter.id);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        setLoading(false);
        history.push(props?.location?.state?.path);
        setUserData([]);
        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
    if (parameter.name === "Activate" || parameter.name === "Deactivate") {
      let req = {
        profileId: parameter.id,
        profileStatus: parameter.status,
      };
      let res = await activeDeactivePostProfile(req);
      if (res.status === 200) {
        setModalLoder(false);
        setOpenModal(false);
        history.push(props?.location?.state?.path);
        // getDetails();
        toast.info(res.message, { theme: "colored" });
      } else {
        setModalLoder(false);
        setOpenModal(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  // const ApproveReport = (id) => {
  //   if (status !== 3) {
  //     setOpenModal(true);
  //     let para = {
  //       name: "Approve",
  //       body: "this Report",
  //       id: id,
  //       status: false,
  //     };
  //     setParameter(para);
  //   }
  // };

  const activDeactivate = (type, id) => {
    if (type === "Deactivate") {
      setOpenModal(true);
      let para = {
        name: "Deactivate",
        body: "this Business profile",
        id: id,
        status: false,
      };
      setParameter(para);
    } else {
      setOpenModal(true);
      let para = {
        name: "Activate",
        body: "this Business profile",
        id: id,
        status: true,
      };
      setParameter(para);
    }
  };

  const DeleteReport = (id) => {
    setOpenModal(true);
    let para = {
      name: "Delete",
      body: "this Business Profile",
      id: id,
    };
    setParameter(para);
  };

  // const Action = async () => {
  //   setModalLoder(true);
  //   let req = {
  //     feed_id: parameter.id,
  //     status: parameter.status,
  //   };

  //   let res = await actionOnFeed(req);
  //   if (res.status === 200) {
  //     setModalLoder(false);
  //     setOpenModal(false);

  //     toast.success(res.message, { theme: "colored" });
  //   } else {
  //     setModalLoder(false);
  //     setOpenModal(false);
  //     toast.error(res.message, { theme: "colored" });
  //   }
  // };

  if (loading) {
    return (
      <div className="LoaderWrapper">
        <div className="BackgroundLoader" />
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <DeleteBlock
          open={openModal}
          close={() => setOpenModal(false)}
          confirmSumbit={deleteAction}
          parameter={parameter}
          loading={modalLoder}
        />
      )}

      {userData !== [] ? (
        <PageInnerContainer>
          <UserInfoBox>
            {/* <div style={{ height: "max-content", display: 'grid', gridTemplateColumns: 'repeat(2,1fr)' }}> */}
            <UserProfileInfo>
              {userData?.postProfile_picture !== [] ? (
                <img
                  src={baseurl + userData?.postProfile_picture[0]}
                  alt="Profile"
                />
              ) : userData?.gender === "male" ? (
                <img src="../media/svg/avatars/001-boy.svg" alt="Profile" />
              ) : (
                <img src="../media/svg/avatars/002-girl.svg" alt="Profile" />
              )}
              <div>
                <h4>{userData?.first_name + " " + userData?.last_name}</h4>
                
                {/* <UserProfileInfoInner>
         
                </UserProfileInfoInner>
                 */}
              </div>
            </UserProfileInfo>
            <ActionButtonForUser>
              {userData?.profile_status === true ? (
                <span
                  className="bg-warning"
                  style={{ padding: "10px", borderRadius: "25px" }}
                  onClick={() => activDeactivate("Deactivate", userData?._id)}
                >
                  Deactivate Profile
                </span>
              ) : (
                <span
                  className="bg-success"
                  style={{ padding: "10px", borderRadius: "25px" }}
                  onClick={() => activDeactivate("Activate", userData?._id)}
                >
                  Activate Profile
                </span>
              )}
              <span
                className="Delete"
                onClick={() => DeleteReport(userData?._id)}
              >
                Delete Profile
              </span>
            </ActionButtonForUser>
            <UserFollowingDetails>
              <div>
                <p>{userData?.count}</p>
                <span>Number of Reports</span>
              </div>
            </UserFollowingDetails>

            {/* </div> */}
          </UserInfoBox>

          <div
            style={{
              textAlign: "center",
              borderBottom: "2px solid red",
              fontSize: "2rem",
              fontWeight: "500",
              paddingTop: "2%",
            }}
          >
            <div>
              <span
                className={activeTab === "info" && "active"}
                onClick={() => setActiveTab("info")}
              >
                Report Info
              </span>
            </div>
            {/* <div>
              <span
                className={activeTab === "video" && "active"}
                onClick={() => setActiveTab("video")}
              >
                Reported By
              </span>
            </div> */}
          </div>

          {activeTab === "info" && (
            <ReportInfo
              data={userData?.reportedByUserData}
              reportData={userData?.reportData}
            />
          )}
          {/* {activeTab === "video" && <ReportedByInfo data={userData} />} */}
        </PageInnerContainer>
      ) : (
        <span className="ml-3 spinner spinner-white"></span>
      )}
    </>
  );
}
